<template>
  <comgate-s-d-k-component/>

  <branch-modal :is-opened="openedBranchModal" :branch="modalBranch" @closeModal="openedBranchModal = false"/>

  <modal-component :is-opened="showOpeningContactBranchModal"
                   :modalClass="'max-w-[404px] mx-auto'"
                   @closeModal="showOpeningContactBranchModal = false"
                   :showClose="true"
                   :show-close-button="false"
                   :escClose="true"
                   :backgroundClose="true">
    <branch-opening-contact-modal :branch="openingContactModalBranch" @closeModal="showOpeningContactBranchModal = false"/>
  </modal-component>

<!--  <gift-card-modal :is-opened="openedGiftCardModal" @closeModal="openedGiftCardModal = false"/>-->
  <modal-component :is-opened="showLoginModal"
         :modalClass="'max-w-[404px] mx-auto'"
         @closeModal="showLoginModal = false"
         :size="'medium'"
         :showClose="true"
         :showCloseButton="false"
         :backgroundClose="true">
    <login-modal :csrf-token-authenticate="csrfTokenAuthenticate" @closeButton="showLoginModal = false"/>
  </modal-component>
  <modal-component :is-opened="showRegistrationModal"
         @closeModal="showRegistrationModal = false"
         :showClose="true"
         :escClose="false"
         :backgroundClose="true">
    <registration-modal :csrf-token-authenticate="csrfTokenAuthenticate"/>
  </modal-component>
  <modal-component :is-opened="showVerificationModal"
         @closeModal="showVerificationModal = false"
         :escClose="verificationModalShowClose"
         :backgroundClose="verificationModalShowClose">
    <template #default>
      <verification-modal :user-data="userData"
                          @updateUserData="updateUserData"
                          @showClose="verificationModalShowClose = true"/>
    </template>
    <template #customCornerClose>
      <a type="button"
         href="logout"
              key="sidePanelCloseButton"
              class="inline-flex text-sm rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0">
        <ArrowLeftOnRectangleIcon class="h-5 w-5 mr-1" aria-hidden="true"/>
        {{ $t('main.buttonCancelRegistration') }}
      </a>
    </template>
  </modal-component>

  <TransitionRoot appear :show="showCartPanel" as="template">
    <Dialog as="div" class="relative z-40" @close="showCartPanel = false">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed top-0 right-0 flex max-w-full pt-20 bottom-63px">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-7000" enter-from="translate-y-full" enter-to="translate-y-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-y-0" leave-to="translate-y-full">
              <DialogPanel class="pointer-events-auto w-screen">
                <div class="flex h-full flex-col overflow-y-scroll bg-white relative rounded-t-xl shadow-modal shadow-ps-cart-modal">

                  <div class="mt-2 mr-2 items-center absolute top-0 right-0 z-40">
                    <button type="button"
                            key="sidePanelCloseButton"
                            class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                            @click="showCartPanel = false">
                      <span class="sr-only">{{ $t('main.buttonClose') }}</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true"/>
                    </button>
                  </div>
                  <div class="relative mt-6 mb-6 flex-1 px-4 sm:px-6">
                    <selected-slots-component v-on:removeCode="removeCode"  v-on:useCode="useCode" :code="discountCode" :voucher-code="voucherCode" :code-error="codeError"/>
                    <div v-if="step === 1" class="mt-6 flex justify-end">
                      <button v-if="selectedSlots.length > 0"
                              @click="step = 2; showCartPanel = false; trackNextStep(2)"
                              :key="'nextButton'"
                              class="bg-ps-yellow-3 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium hover:bg-ps-yellow-3-inactive focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ps-yellow-3-inactive">
                        {{ $t('main.buttonContinue') }}
                      </button>
                      <button v-else
                              disabled="disabled"
                              :key="'nextButtonDisabled'"
                              class="bg-gray-200 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-400 cursor-not-allowed">
                        {{ $t('main.buttonContinue') }}
                      </button>

                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <div class="min-h-screen bg-white flex flex-col justify-between">
    <nav-bar-component :selected-slots="selectedSlots" :show-cart-panel="showCartPanel" @toggleCartPanel="toggleCartPanel" @openLoginModal="showLoginModal = true" @openRegistrationModal="showRegistrationModal = true" :user-data="userData"/>

    <router-view/>

    <footer-component/>

    <nav-bottom-bar-component :show-cart-panel="showCartPanel" @toggleCartPanel="toggleCartPanel" v-if="step < 2"/>
  </div>
</template>

<script setup>
import { XMarkIcon, ArrowLeftOnRectangleIcon } from '@heroicons/vue/20/solid'
import SelectedSlotsComponent from "./SelectedSlotsComponent"
import ComgateSDKComponent from './ComgateSDKComponent'

import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import NavBarComponent from './NavBarComponent'
import NavBottomBarComponent from './NavBottomBarComponent'
import FooterComponent from './FooterComponent'
import BranchModal from './BranchModal'
import BranchOpeningContactModal from './BranchOpeningContactModal'
// import GiftCardModal from './GiftCardModal'
import ModalComponent from './ModalComponent'
import LoginModal from './LoginModal'
import RegistrationModal from './RegistrationModal'
import VerificationModal from './VerificationModal'

</script>

<script>
import discountCodeMixin from '../mixins/discountCodeMixin'
import { mapActions, mapState } from 'pinia'
import { useStore } from '../js/store'

export default {
  name: 'MainComponent',
  props: {
    msg: String,
    reservation: {
      type: Object,
      required: false,
      default: null,
    },
    csrfTokenAuthenticate: {
      type: String,
      required: false
    },
  },
  data: function () {
    return {
      showCartPanel: false,
      updateCalendar: false,
      branchList: [],
      branchLoading: true,
      openedGiftCardModal: false,
      contact: {
        firstName: '',
        lastName: '',
        phone: '+420',
        email: '',
        terms: true,
        personalData: true
      },
      loginModalSize: 'small',
      contactError: false,
      validateForm: false,
      formIsValid: false,
      waitForValidation: false,
      postOrderProcessing: false,
      summary: null,
      loadingReservation: false,
      updateSummary: false,
      //
      openedBranchModal: false,
      modalBranch: {},
      //
      showLoginModal: false,
      showRegistrationModal: false,
      // userData: {},
      showVerificationModal: false,
      verificationModalShowClose: false,
      // userIsVerified: false
      showOpeningContactBranchModal: false,
      openingContactModalBranch: {},
    }
  },
  mixins: [discountCodeMixin],

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    ...mapState(useStore, {
      selectedSlots: 'selectedSlots'
    }),
    ...mapState(useStore, {
      step: 'reservationStep'
    }),
    ...mapState(useStore, {
      userData: 'user'
    }),
  },
  watch: {
    // step(newStep, oldStep) {
    //   if (oldStep === 0 && newStep > 0) {
    //     this.$nextTick(() => {
    //       console.log('scrolluji pod menu')
    //       setTimeout(() => {
    //         window.scrollTo(0, 50);
    //       }, 100); // Zpoždění 100ms
    //       // window.scrollTo(0, 50)
    //     })
    //   }
    // }
  },
  methods: {
    ...mapActions(useStore, {setUser: 'setUser'}),

    trackNextStep(step) {
      if (step === 2) {
        // this.$gtm.trackEvent({
        //   event: 'select_slot',
        //   category: 'Booking',
        //   action: 'click',
        //   label: 'select slot',
        //   'slot_id': slot.id
        // })
      }
    },
    updateUserData(userData) {
      this.setUser(userData)
    },
    toggleCartPanel() {
      this.showCartPanel = !this.showCartPanel
    },
    logout() {
    }
  },
  // mounted() {
  //   document.addEventListener('visibilitychange', this.handleVisibility, false)
  //
  //   this.getBranches()
  // },
  mounted() {
    this.emitter.on('openBranchModal', branch => {
      this.modalBranch = branch
      this.openedBranchModal = true
    })

    this.emitter.on('openBranchOpeningModal', branch => {
      this.openingContactModalBranch = branch
      this.showOpeningContactBranchModal = true
    })

    this.emitter.on('openGiftCardModal', () => {
      this.openedGiftCardModal = true
    })

    this.emitter.on('updateSelectedSlots', selectedSlots => {
      this.selectedSlots = selectedSlots
    })

    window.onbeforeunload = function(){
      if (this.step === 3) {
        this.getUserLeft()
      }
    }
  },
  updated() {
    this.showVerificationModal = this.userData.userEmail !== undefined && (this.userData.userEmailVerified === false || this.userData.userPhoneVerified === false)
  }
}
</script>
