<template>
  <div class="text-right">
    <Menu as="div" class="relative inline-block text-left">
        <MenuButton class="shadow-sm inline-flex items-center text-gray-600 hover:text-gray-900 border border-gray-500 hover:border-gray-900 px-3 py-2 rounded-md text-sm font-medium">
          {{ userData.userFirstName }}
          <Bars3Icon class="ml-2 -mr-1 h-5 w-5 hover:color-violet-100 color-gray-500 hover:text-gray-900" aria-hidden="true"/>
        </MenuButton>

      <transition :show="false"
                  appear
                  enter-active-class="transition duration-100 ease-out"
                  enter-from-class="transform scale-95 opacity-0"
                  enter-to-class="transform scale-100 opacity-100"
                  leave-active-class="transition duration-75 ease-in"
                  leave-from-class="transform scale-100 opacity-100"
                  leave-to-class="transform scale-95 opacity-0">
        <MenuItems class="absolute right-0 mt-2 w-64 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div class="px-1 py-1">
            <MenuItem v-slot="{ active }">
                <button @click="handleGoTo('UserReservationList')"
                        key="myBookingsButton"
                        :class="[
                  active ? 'bg-gray-100 text-black' : 'text-gray-900',
                  'group flex w-full items-center rounded-md px-2 py-2 text-sm',]">
                  {{ $t('userMenu.myReservations') }}
                </button>
            </MenuItem>
          </div>
<!--          <div class="px-1 py-1">-->
<!--            <MenuItem v-slot="{ active }">-->
<!--                <button @click="handleGoTo('AutomaticReservation')"-->
<!--                        key="automaticReservationButton"-->
<!--                        :class="[-->
<!--                  active ? 'bg-gray-100 text-black' : 'text-gray-900',-->
<!--                  'group flex w-full items-center rounded-md px-2 py-2 text-sm',]">-->
<!--&lt;!&ndash;                  {{ $t('userMenu.automaticReservation') }}&ndash;&gt;-->
<!--                  Automatická rezervace-->
<!--                  <span class="inline-flex items-center justify-center text-xs ml-2 px-1 py-0.5 text-ps-yellow-dark bg-ps-yellow-3 rounded-md">novinka</span>-->
<!--                </button>-->

<!--            </MenuItem>-->
<!--          </div>-->
          <div class="px-1 py-1">
            <MenuItem v-slot="{ active }">
              <a :class="[
                 active ? 'bg-gray-100 text-black' : 'text-gray-900',
                  'group flex w-full items-center rounded-md px-2 py-2 text-sm',]"
                 key="logoutButton"
                 href="logout">
                {{ $t('userMenu.logOut') }}
              </a>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script setup>
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { Bars3Icon } from '@heroicons/vue/24/solid'

</script>

<script>
export default {
  props: ['userData'],
  data: function () {
    return {
    }
  },
  methods: {
    handleGoTo(nameTo) {
      // Do your stuff
      this.$router.push({ name: nameTo });
    }
  },
  mounted() {
  }
}
</script>
