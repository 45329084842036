

export default {
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    length: {
      type: Number,
      default: 4,
    },
    autofocus: {
      type: Boolean,
      default: true,
    },
    secure: {
      type: Boolean,
      default: false,
    },
    characterPreview: {
      type: Boolean,
      default: true,
    },
    charPreviewDuration: {
      type: Number,
      default: 300,
    },
    baseRefName: {
      type: String,
      required: true
    },
    errorArray: {
      default: '',
      required: false
    },
    note: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: () => ({
    focusedCellIdx: 0,
    cells: [],
    watchers: {},
    cellsInputTypes: {},
  }),
  computed: {
    pinCodeComputed(): string {
      return this.cells.reduce((pin, cell) => pin + cell.value, '')
    },
  },
  watch: {
    value(value: any) {
      if (value) {
        this.onParentValueUpdated()
      } else {
        this.reset()
      }
    },
    length() {
      this.reset()
    },
    pinCodeComputed(newValue: string) {
      if (this.length === newValue.length) {
        this.$emit('update:modelValue', newValue)
      }
    },
  },
  mounted() {
    this.init()
    this.onParentValueUpdated()
    if (this.autofocus) {
      this.$nextTick(this.focusCellByIndex)
    }
  },
  methods: {
    /* init stuff */
    init(): void {
      for (let key = 0; key < this.length; key += 1) {
        this.setCellObject(key)
        this.setCellWatcher(key)
      }
    },
    setCellObject(key: number): void {
      this.cells[key] = {key, value: ''}
    },
    setCellWatcher(index: number): void {
      const watchingProperty = `cells.${index}.value`
      this.watchers[watchingProperty] = this.$watch(
          watchingProperty,
          (newVal, oldVal) => this.onCellChanged(index, newVal, oldVal),
      )
    },
    /* handlers */
    onParentValueUpdated(): void {
      if (this.modelValue.length !== this.length) {
        this.$emit('input', this.pinCodeComputed)
        return
      }
      this.modelValue
          .split('')
          .forEach((cell: string, idx: number) => {
            this.cells[idx].value = cell || ''
          })
    },
    onCellChanged(index: number, newVal: string, oldVal: string): void {
      if (this.isTheCellValid(newVal, false)) {
        this.focusNextCell()
      } else if (this.isTheCellValid(newVal.replace(oldVal, ''), false)) {
        this.cells[index].value = newVal.replace(oldVal, '')
        return
      } else if (!this.isTheCellValid(newVal, false)) {
        this.cells[index].value = ''
        return
      }
    },
    onCellErase(index: number, e: Event): void {
      const isThisCellFilled = this.cells[index].value.length
      if (!isThisCellFilled) {
        this.focusPreviousCell()
        e.preventDefault()
      }
    },
    onKeyDown(e: KeyboardEvent): void {
      switch (e.keyCode) {
          /* left arrow key */
        case 37:
          this.focusPreviousCell()
          break
          /* right arrow key */
        case 39:
          this.focusNextCell()
          break
        default:
          break
      }
    },
    /* reset stuff */
    reset(): void {
      this.unwatchCells()
      this.init()
      this.focusCellByIndex()
    },
    unwatchCells(): void {
      const watchers = Object.keys(this.watchers)
      watchers.forEach(name => this.watchers[name]())
    },
    /* helpers */
    isTheCellValid(cell: string, allowEmpty = true): boolean {
      if (!cell) {
        return allowEmpty ? cell === '' : false
      }
      return !!cell.match('^\\d{1}$')
    },
    focusPreviousCell(): void {
      if (!this.focusedCellIdx) return
      this.focusCellByIndex(this.focusedCellIdx - 1)
    },
    focusNextCell(): void {
      if (this.focusedCellIdx === this.length - 1) return
      this.focusCellByIndex(this.focusedCellIdx + 1)
    },
    focusCellByIndex(index: number = 0): void {
      const ref = `${this.baseRefName}${index}`
      const el = (this.$refs[ref] as HTMLInputElement[])[0]
      el.focus()
      el.select()
      this.focusedCellIdx = index
    },
  },
}

