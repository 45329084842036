<template>
  <div v-if="verificationStep === 1">
    <h1 class="text-3xl text-center">{{ $t('verificationModal.emailVerificationTitle') }}</h1>
    <div class="mt-8">
      <div class="flex flex-row justify-center">
        <input :value="userData.userEmail"
               type="text"
               disabled="disabled"
               class="block shadow-sm sm:text-sm border-gray-300 bg-gray-200 rounded-md"/>

        <button :key="'verifyEmailButton'"
                type="button"
                @click="sendVerificationPin"
                class="bg-pg-yellow border border-transparent rounded-md shadow-sm py-2 px-4 ml-6 inline-flex justify-center text-sm font-medium hover:bg-pg-yellow-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pg-yellow-light">
          {{ $t('verificationModal.sendVerificationEmail') }}
        </button>
      </div>
    </div>
  </div>
  <div v-else-if="verificationStep === 2">
    <h1 class="text-3xl text-center">{{ $t('verificationModal.emailVerificationTitle') }}</h1>
    <div class="mt-8">
      <div class="mt-8 text-center text-sm">
        <p>
          {{ $t('verificationModal.emailCodePrompt') }}
        </p>
      </div>
      <p-i-n-component :length="5"
                       v-model="pinCodeMail"
                       base-ref-name="emailCode"
                       :disabled="verifying"
                       :error-array="formValidation.emailPin"/>
      <div class="mt-4 text-center text-xs" v-if="verifying === true">
        <p>{{ $t('verificationModal.verifyingCode') }}</p>
      </div>
      <div class="mt-4 text-center text-xs" v-else>
        <p>{{ $t('verificationModal.deliveryNote') }}</p>
        <p>{{ $t('verificationModal.codeValidity') }} <a @click="sendVerificationPin" class="cursor-pointer text-black underline hover:no-underline">{{ $t('verificationModal.resendCodeLink') }}</a></p>
      </div>
    </div>
  </div>
  <div v-else-if="verificationStep === 3">
    <h1 class="text-3xl text-center">{{ $t('verificationModal.phoneVerificationTitle') }}</h1>
    <div class="mt-8">
      <div class="flex flex-row justify-center">
        <input :value="userData.userPhoneNumber"
               type="text"
               disabled="disabled"
               class="block shadow-sm sm:text-sm border-gray-300 bg-gray-200 rounded-md"/>

        <button :key="'verifySMSButton'"
                type="button"
                @click="sendVerificationPin"
                class="bg-pg-yellow border border-transparent rounded-md shadow-sm py-2 px-4 ml-6 inline-flex justify-center text-sm font-medium hover:bg-pg-yellow-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pg-yellow-light">
          {{ $t('verificationModal.sendVerificationSms') }}
        </button>
      </div>
    </div>
  </div>
  <div v-else-if="verificationStep === 4">
    <h1 class="text-3xl text-center">{{ $t('verificationModal.phoneVerificationTitle') }}</h1>
    <div class="mt-8">
      <div class="mt-8 text-center text-sm">
        <p>
          {{ $t('verificationModal.smsCodePrompt') }}
        </p>
      </div>
      <div class="flex justify-center mt-4">
        <p-i-n-component :length="4" v-model="pinCodeSMS" base-ref-name="smsCode"/>
      </div>
      <div class="mt-4 text-center text-xs">
        <p>{{ $t('verificationModal.smsDeliveryNote') }}</p>
      </div>
    </div>
  </div>
  <div v-else-if="verificationStep === 5">
    <h1 class="text-3xl text-center">{{ $t('verificationModal.doneTitle') }}</h1>
    <div class="text-2xl mt-2 text-center">{{ $t('verificationModal.profileReady') }}</div>
    <div class="mt-8 flex items-center justify-center">
      <CheckCircleIcon class="block h-12 w-12 text-green-500" aria-hidden="true" />
    </div>
  </div>
</template>


<script setup>
import { CheckCircleIcon } from '@heroicons/vue/24/outline'

</script>

<script>
import PINComponent from './PINComponent'

export default {
  name: 'VerificationModal',

  props: ['userData'],

  data: function () {
    return {
      baseRefName: 'emailpin',
      verificationStep: 1,
      // verificationStep: 2, // TODO - DEMO SMAZAT
      autofocusEmailPin: false,
      // OLD:
      validated: false,
      loginEmail: '',
      loginPass: '',
      emailError: null,
      passError: null,
      registerForm: {
        firstName: '',
        lastName: '',
        email: '',
        plainPasswordOne: '',
        plainPasswordTwo: '',
        phoneNumber: '',
      },
      formValidation: {
        emailPin: '',
        smsPin: '',
      },
      focusedCellIdx: 0,
      pinCodeMail: '',
      pinCodeSMS: '',
      verifying: false,
    }
  },
  watch: {
    pinCodeMail(val) {
      this.verifyEmailCode(val)
    },
    pinCodeSMS(val) {
      this.verifySMSCode(val)
    },
    verificationStep(val) {
      if (val === 5) {
        this.$emit('showClose')
      }
    }
  },
  methods: {
    verifyEmailCode(emailCode) {
      this.verifyPin(emailCode, 'email')
    },
    verifySMSCode(smsCode) {
      this.verifyPin(smsCode, 'sms')
    },
    verifyPin(pin, type) {
      this.verifying = true
      this.$http.post('verify-pin', {pin: pin, type: type})
          .then(response => {
            this.verifying = false
            this.verificationStep = response.data.step
            if (response.data.userData) {
              this.$emit('updateUserData', response.data.userData)
            }
          })
          .catch(error => {
            this.verifying = false
            if (error.response.data.formValidation) {
              this.formValidation = error.response.data.formValidation
            }
          })
    },
    sendVerificationPin() {
      this.$http.post('verification-pin')
          .then(response => {
            this.verificationStep = response.data.step
            if (response.data.userData) {
              this.$emit('updateUserData', response.data.userData)
            }
          })
          .catch({})
    },
  },
  mounted: function () {
    if (this.userData.userEmailVerified === true) {
      this.verificationStep = 3
    }
  },
  components: {
    PINComponent
  }
}
</script>
