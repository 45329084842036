import {mapActions, mapState} from 'pinia'
import {useStore} from '../js/store'


const discountCodeMixin = {
    data() {
        return {
            codeError: null,
        }
    },
    computed: {
        ...mapState(useStore, {
            discountCode: 'discountCode'
        }),
        ...mapState(useStore, {
            voucherCode: 'voucherCode'
        }),
    },
    methods: {
        ...mapActions(useStore, {setDiscountCode: 'setDiscountCode'}),
        ...mapActions(useStore, {setVoucherCode: 'setVoucherCode'}),
        ...mapActions(useStore, {removeDiscountCode: 'removeDiscountCode'}),

        useCode(code) {
            this.postCode(code)
        },
        removeCode() {
            this.removeDiscountCode()
        },

        postCode(code) {
            this.$http.post('code', {code: code})
                .then(response => {
                    if (response.data.rest_value) {
                        this.setVoucherCode(response.data)
                    } else {
                        this.setDiscountCode(response.data)
                    }
                })
                .catch(error => {
                    this.codeError = error.response.data.codeError
                })
        }
    }
}

export default discountCodeMixin
