const ReservationComponent = () => import('../components/ReservationComponent')
const UserReservationList = () => import('../components/UserReservationList')
const AdminDashboard = () => import('../components/admin/DashboardComponent')
const ErrorPageComponent = () => import('../components/ErrorPageComponent')

import { createRouter, createWebHistory, RouterView } from 'vue-router'
import { useStore } from '../js/store'
import i18n from '../i18n'

const router = createRouter({
  // mode: 'history',
  history: createWebHistory(),
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/:locale(cs|en)?',
      component: RouterView,
      children: [
        {
            path: 'darkovy-poukaz',
            name: 'GiftPage',
            component: ReservationComponent,
            meta: {
              giftRoute: true
            }
        },
        {
            path: 'automaticka-rezervace',
            name: 'AutomaticReservation',
            component: ReservationComponent,
            meta: {
              automaticReservationRoute: true,
              requiresAuth: true
            }
        },
        {
          path: 'moje-rezervace',
          name: 'UserReservationList',
          component: UserReservationList,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: 'admin',
          name: 'AdminDashboard',
          component: AdminDashboard,
          meta: {
            requiresAuth: true,
            requiresRole: 'ROLE_ADMIN'
          },
        },
        {
          path: '',
          name: 'NewReservation',
          component: ReservationComponent
        },
        {
          path: 'paid',
          name: 'PaidReservation',
          component: ReservationComponent
        },
        {
          path: 'rebook',
          name: 'RebookReservation',
          component: ReservationComponent
        }
      ]
    },
    {
      path: '/:anything(.*)*',
      component: ErrorPageComponent
    }
  ]
})

router.beforeEach((to, from, next) => {
  const store = useStore()

  const newLocale = to.params.locale
  const prevLocale = from.params.locale

  console.log('newLocale:' + newLocale)
  console.log('prevLocale:' + prevLocale)
  if (newLocale !== prevLocale && newLocale !== undefined) {
    if (newLocale === '') {
      i18n.setLocaleToDefaultLocale()
      store.setDateLocaleCode(i18n.defaultLocale)
    } else {
      i18n.setLocale(newLocale)
      store.setDateLocaleCode(newLocale)
    }
  }

  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth)
  const userRoles = store.userRoles
  const isLoggedIn = store.userIsLoggedIn

  if (to.meta.requiresRole && !userRoles.includes(to.meta.requiresRole)) {
    console.log('asi nemám roli dopíči')
    next('/')
  } else if (requiresAuth && !isLoggedIn) {
    console.log('tady??? ' + requiresAuth + ' ' + isLoggedIn)
    next('/')
  } else {
    console.log('tááádyyyyyasadasd')
    next()
  }
})

export default router
