import { createI18n } from 'vue-i18n'
import messages from '../locale/locale'

export const defaultLocale = 'cs'
// Private instance of VueI18n object
let _i18n
// Initializer
function setup(options = { locale: defaultLocale }) {
    _i18n = createI18n({
        locale: options.locale,
        fallbackLocale: defaultLocale,
        messages,
        // numberFormats,
        // datetimeFormats,
        // pluralizationRules: {
        //     'ar-EG': arabicPluralRules,
        // },
    })
    setLocale(options.locale)
    return _i18n
}
// Sets the active locale.
function setLocale(newLocale) {
    _i18n.global.locale = newLocale
}

// Sets the active locale.
function setLocaleToDefaultLocale() {
    _i18n.global.locale = defaultLocale
}
// Public interface
export default {
    // Expose the VueI18n instance via a getter
    get vueI18n() {
        return _i18n
    },
    setup,
    setLocale,
    setLocaleToDefaultLocale,
    defaultLocale
}
