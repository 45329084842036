<template>
  <div>
    <h1 class="mb-4 text-xl text-center">{{ branch.street }}, {{ branch.city }}</h1>

    <template v-if="step === 1">
      <div class="text-sm mb-2">{{ $t('branchOpeningContactModal.description') }}</div>
      <form>
        <div class="grid grid-cols-4 gap-x-6 gap-y-3">

          <common-input class="col-span-4"
                        v-model="notificationForm.email"
                        :error-array="formValidation.email"
                        type="text"
                        :label="$t('branchOpeningContactModal.emailAddress')"
                        id="email_address"
                        name="emailAddress"
                        autocomplete="email"
                        :note="$t('branchOpeningContactModal.emailNote')"/>

          <input type="hidden" name="locale" :value="dateLocaleCode">

          <div class="col-span-4 sm:col-span-4 mt-4">
            <p class="text-gray-700 text-sm" v-html="$t('branchOpeningContactModal.tAndCHtml')"/>
          </div>

          <div class="col-span-4 mt-4 text-center">
            <button :key="'subscribeButton'"
                    type="button"
                    @click="postSubscriptionForm"
                    class="bg-pg-yellow border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium hover:bg-pg-yellow-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pg-yellow-light">
              {{ $t('branchOpeningContactModal.button') }}
            </button>
          </div>
        </div>
      </form>
    </template>
    <template v-else-if="step === 2">
      <div class="text-sm mb-2">{{ $t('branchOpeningContactModal.descriptionAfterSubscribe') }}</div>
      <div class="col-span-4 mt-4 text-center">
        <button @click="closeModal"
                :key="'closeButton'"
                class="inline-flex items-center py-2 px-4 text-xs md:text-sm font-medium text-center text-gray-900 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200">
          {{ $t('modal.buttonClose') }}
        </button>
      </div>
    </template>
  </div>
</template>


<script>
import CommonInput from './CommonInput'
import { mapState } from 'pinia'
import { useStore } from '../js/store'

export default {
  name: "RegistrationModal",

  props: ['branch'],

  data: function () {
    return {
      step: 1,
      notificationForm: {
        email: '',
        branchId: null
      },
      formValidation: {}
    }
  },
  computed: {
    ...mapState(useStore, {
      dateLocaleCode: 'dateLocaleCode'
    }),
  },
  methods: {
    postSubscriptionForm() {
      let notificationForm = { ...this.notificationForm, locale: this.dateLocaleCode }
      this.$http.post('subscribe-branch-opening', notificationForm)
          .then(() => {
            this.step = 2
          })
          .catch(error => {
            if (error.response.data.formValidation) {
              this.formValidation = error.response.data.formValidation
            }
          })
    },
    closeModal() {
      this.$emit('closeModal')
    }
  },
  mounted() {
    this.step = 1
    this.notificationForm.branchId = this.branch.id
  },
  components: {
    CommonInput
  }
}
</script>

<style scoped>

</style>
