<template>
  <div>
    <template v-if="showForgottenPasswordForm === false">
      <h1 class="mb-8 text-3xl text-center">{{ $t('loginModal.signIn') }}</h1>
      <form action="/login" ref="logform" method="POST" v-on:keyup.enter="postLoginForm">
        <common-input class="col-span-4 sm:col-span-4"
                      v-model="loginForm.email"
                      :error-array="formValidation.email"
                      type="text"
                      :label="$t('loginModal.emailAddress')"
                      id="email_address"
                      name="email"
                      :autofocus="true"
                      autocomplete="email"/>

        <common-input class="col-span-4 sm:col-span-4 mt-4"
                      v-model="loginForm.pass"
                      :error-array="formValidation.password"
                      type="password"
                      :label="$t('loginModal.password')"
                      id="pass"
                      name="password"
                      autocomplete="email"/>

        <input type="hidden" name="_csrf_token" :value="csrfTokenAuthenticate">

        <div class="flex items-center justify-end mt-4">
          <div class="text-sm">
            <a @click="showForgottenPasswordForm = true" class="font-medium text-gray-600 hover:text-gray-500 cursor-pointer">
              {{ $t('loginModal.forgotPasswordButton') }}
            </a>
          </div>
        </div>
        <div class="flex items-center mt-4">
          <button :key="'loginButton'"
                  type="button"
                  @click="postLoginForm"
                  class="w-full bg-pg-yellow border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium hover:bg-pg-yellow-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pg-yellow-light">
            {{ $t('loginModal.signInButton') }}
          </button>
        </div>
        <div class="flex items-center mt-4">
          <button key="closeButton"
                  type="button"
                  @click="$emit('closeButton')"
                  class="w-full inline-flex items-center py-2 px-4 text-xs md:text-sm font-medium text-center justify-center text-gray-900 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200">
            {{ $t('loginModal.cancelButton') }}
          </button>
        </div>
      </form>
    </template>
    <template v-else>
      <h1 class="mb-8 text-3xl text-center">{{ $t('loginModal.forgottenPassword') }}</h1>
      <form v-if="showForgottenPasswordSent === false">
        <common-input class="col-span-4 sm:col-span-4"
                      v-model="passwordResetRequestFormForm.email"
                      type="text"
                      :label="$t('loginModal.emailAddress')"
                      :note="$t('loginModal.enterEmailResetPassword')"
                      id="email_address_forgotten_password"
                      name="email"
                      autocomplete="email"/>

        <div class="flex items-center justify-end mt-4">
          <div class="text-sm">
            <a @click="showForgottenPasswordForm = false"
               class="font-medium text-grat-600 hover:text-gray-500 cursor-pointer">
              {{ $t('loginModal.knowPasswordSignIn') }}
            </a>
          </div>
        </div>
        <div class="flex items-center justify-between mt-4">
          <button :key="'sendForgottenPassEmailButton'"
                  type="button"
                  @click="postPasswordResetRequestForm"
                  class="w-full bg-pg-yellow border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium hover:bg-pg-yellow-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pg-yellow-light">
            {{ $t('loginModal.sendEmailResetPassword') }}
          </button>
        </div>
        <div class="flex items-center mt-4">
          <button key="closeButton"
                  type="button"
                  @click="$emit('closeButton')"
                  class="w-full inline-flex items-center py-2 px-4 text-xs md:text-sm font-medium text-center justify-center text-gray-900 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200">
            {{ $t('loginModal.cancelButton') }}
          </button>
        </div>
      </form>
      <div v-else>
        <p>
          {{ $t('loginModal.passwordResetEmailSent') }}
        </p>
        <p>
          {{ $t('loginModal.emailInstruction') }}
        </p>
        <p>
          {{ $t('loginModal.checkSpam') }} <a class="underline text-grat-600 hover:text-gray-500 cursor-pointer" @click="showForgottenPasswordSent = false">{{ $t('loginModal.tryAgain') }}</a>
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import CommonInput from './CommonInput'

export default {
  name: "LoginModal",

  props: ['csrfTokenAuthenticate'],

  data: function () {
    return {
      loginForm: {
        email: '',
        pass: ''
      },
      passwordResetRequestFormForm: {
        email: ''
      },
      loginEmail: '',
      loginPass: '',
      emailError: null,
      passError: null,
      formValidation: {},
      showForgottenPasswordForm: false,
      showForgottenPasswordSent: false
    }
  },
  // watch: {
  //   showForgottenPasswordForm(newVal) {
  //     this.$emit('resizeLoginModal', newVal === true ? 'medium' : 'small')
  //   }
  // },
  methods: {
    blurEmail() {
    },
    blurPass() {
    },
    postLoginForm() {
      this.$http.post('pre-validate-login', {password: this.loginForm.pass, email: this.loginForm.email, _csrf_token: this.csrfTokenAuthenticate})
          .then(() => {
            this.$refs.logform.submit()
          })
          .catch(error => {
            if (error.response.data.formValidation) {
              this.formValidation = error.response.data.formValidation
            }
          })
    },
    onEnter() {
    },
    postPasswordResetRequestForm() {
      this.$http.post('request-password-reset', {email: this.passwordResetRequestFormForm.email})
          .then(() => {
            this.showForgottenPasswordSent = true
          })
          .catch(() => {
            this.showForgottenPasswordSent = true
          })
    }
  },
  components: {
    CommonInput
  }
}
</script>

<style scoped>

</style>
