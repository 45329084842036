<template>
  <div v-if="showComgateSDKPayment === true && paymentError === false">
    <div id="apple-pay-button-box"></div>
  </div>
</template>

<script setup>

</script>

<script>
export default {
  emits: [''],
  data: function () {
    return {
      applePayInstanceRef: null,
      googlePayInstanceRef: null,
      checkoutInstanceRef: null,
      showComgateSDKPayment: false,
      paymentError: false,
    }
  },
  methods: {
    removeButtons() {
      if (this.applePayInstanceRef) {
        this.applePayInstanceRef.unmount();
      }
      // if (googlePayInstanceRef) {
      //   googlePayInstanceRef.unmount();
      // }
      // let element = document.querySelector("#checkout-button-box");
      // if (element) {
      //   // remove the button box from the page
      //   element.remove();
      // }
    },

    createApplePay(checkoutInstance) {
      checkoutInstance.createApplePay({ // alternative functional notation for createApplePay({}), createApplePay()
          // a complete list of parameters is available at https://apidoc.comgate.cz/?lang=en#definition-of-components-api
          style: {
            // values according to Apple Pay documentation
            style: "black", // (optional, default: black, options: black, white, white-outline) button style
            type: "pay", // (optional, default: pay, options: plain, buy, book, donate, ...) button type
            width: "190px", // default: 100%
            height: "38px", // default: 100%
          },
          locale: "cs-CZ" // (optional, default: cs-CZ, options: en-US, de-DE, sk-SK, ...) UI language
        })
      .then(async (applepayInstance) => {
        this.applePayInstanceRef = applepayInstance;
        console.log(applepayInstance)
        const res = await applepayInstance.canMakePayment();
        applepayInstance.mount("#apple-pay-button-box");

        if (res.result === true) {
          applepayInstance.mount("#apple-pay-button-box");
        } else {
          console.log('res')
          console.log(res)
          console.log("Comgate Checkout: Apple Pay is not available on this device");
        }
      })
      .catch((error) => {
        console.log("Comgate Checkout: Error creating Apple Pay button.", error);
      });
    }
  },
  computed: {
  },
  mounted() {
    console.log('mounted')
    if (window.location.href.indexOf('xxappletestpaymentxx') > -1) {
      this.showComgateSDKPayment = true
      console.log('jedeeem')
    }
    console.log()

    // creation of ComgateCheckout instance
    this.$comgateCheckout({
        checkoutId: 'ed5b8113-0aa8-487c-8bdb-5dc612a82e7b', // (mandatory) obtained in step 5
        locale: "cs", // (optional, default: cs) UI language

        production: false,
        test: true,
        debug: true, // (optional, default: false) detailed development information to the console !!! DO NOT USE 'true' ON PRODUCTION !!!

        // list only the services you want to use
        prefetch: [], // (optional) preload the script for Google Pay (Apple Pay is always preloaded)

        onRequirePayment: (payload) => { // (mandatory) Incorporation and delivery of Comgate transaction ID is requested
          payload
            .eshopRequest({
              method: 'POST',
              url: 'https://pg-master/api/payment/create_inline',
              validStatus: 200, // (optional, default: 200) HTTP status code that is considered successful
              checkSuccessParam: true, // (optional, default: false) response is JSON and contains the 'success' parameter, which must be true
              body: { // send as POST JSON body (POST only)
                orderId: '12155', // Order ID in online store
              },
            })
            .then((response) => {
              console.log('RESPONSE::::')
              console.log(response)
              console.log(response.comgateTransactionId)
              console.log('::::RESPONSE')
              // OK
              if (response && response.comgateTransactionId) {
                console.log('tady:' + response.comgateTransactionId)
                payload.resolve(response.comgateTransactionId);
              } else {
                // invokes catch
                return Promise.reject("Error during payment creation.");
              }
            })
            .catch((error) => {
              // request failed
              console.log("Checkout eshopRequest ERROR: ", error);
              payload.reject();
            });
          // you can use the helper method supplied in payload to send requests to your server payload.eshopRequest(/*...*/)
          // or by saving the ComgateCheckout instance into the checkoutInstance.eshopRequest(/*...*/)
        },
        onPaid: (payload) => { // (mandatory) payment has been paid
          console.log(payload)
          this.removeButtons();
          // alert("The payment was successfully made.");
        },
        onCancelled: (payload) => { // (mandatory) payment was cancelled or expired
          console.log(payload)
          this.removeButtons();
          // alert("Payment was cancelled or expired.");
        },
        onPending: (payload) => { // (optional) if the payment attempt failed, the payment can continue
          console.log(payload)
          // alert("Payment failed, try again.");
          // if no action is taken, it is advisable not to define the callback
        },
        onClick: (payload) => { // (optional) called after clicking the button, before processing the payment [confirmation required]
          // if no action is taken, it is advisable not to define the callback

          // payload.resolve();

          // alert("Payment will be made.");
          //
          // // at the end of the defined callback you must call
          // //     payload.resolve() - continue payment
          // //     payload.reject() - cancel the action and do not continue
          // // otherwise the wait will get stuck
          if ("resolve" in payload) {
            payload.resolve();
          }
        },
        onError: (payload) => { // (mandatory) Error details during processing
          console.log(payload)
          console.log('neco se nepovedlo')
          this.removeButtons()
            // A substantial part of the payment processing is done asynchronously and therefore cannot be linked to the standard try-catch block.
            // if this callback is called, the payment is no longer continued, the Comgate ID of the transaction can be used again
            // most errors are automatically reported to Comgate for their tuning and possible correction
            // errors caused by improper implementation of the developer will not be corrected
          }
        })
      .then((checkoutInstance) => {
        console.log(checkoutInstance)
        // configuration of specific services
        this.checkoutInstanceRef = checkoutInstance
        this.createApplePay(checkoutInstance)
        // demo_createGooglePay(checkoutInstance);
      })
      .catch((error) => {
        console.log(error)
        this.removeButtons();
        // an unexpected error occurred while creating the ComgateCheckout instance and payments cannot be processed
        console.error("ComgateCheckout Init ERROR: ", error);
      });
  }
}
</script>
