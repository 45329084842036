<template>
  <div>
    <h1 class="mb-8 text-3xl text-center">{{ $t('registrationModal.title') }}</h1>
    <form action="register" ref="regform" method="post">
      <div class="grid grid-cols-4 gap-x-6 gap-y-3">

        <common-input class="col-span-4 sm:col-span-2"
                      v-model="registerForm.firstName"
                      :error-array="formValidation.firstName"
                      type="text"
                      :label="$t('registrationModal.firstName')"
                      id="first_name"
                      name="firstName"
                      autocomplete="given-name"/>

        <common-input class="col-span-4 sm:col-span-2"
                      v-model="registerForm.lastName"
                      :error-array="formValidation.lastName"
                      type="text"
                      :label="$t('registrationModal.lastName')"
                      id="last_name"
                      name="lastName"
                      autocomplete="family-name"/>

        <common-input class="col-span-4 sm:col-span-2"
                      v-model="registerForm.email"
                      :error-array="formValidation.email"
                      type="text"
                      :label="$t('registrationModal.emailAddress')"
                      id="email_address"
                      name="emailAddress"
                      autocomplete="email"
                      :note="$t('registrationModal.emailNote')"/>

        <common-input class="col-span-4 sm:col-span-2"
                      v-model="registerForm.phoneNumber"
                      :error-array="formValidation.phoneNumber"
                      type="text"
                      :label="$t('registrationModal.phone')"
                      id="phone_number"
                      name="phoneNumber"
                      autocomplete="phone"
                      :note="$t('registrationModal.phoneNote')"/>

        <common-input class="col-span-4 sm:col-span-4 mt-2"
                      v-model="registerForm.plainPasswordOne"
                      :error-array="formValidation.plainPasswordOne"
                      type="password"
                      :label="$t('registrationModal.password')"
                      id="plainPasswordOne"
                      name="new-password-one"
                      autocomplete="xqwerfvsdc"/>

        <common-input class="col-span-4 sm:col-span-4 mb-2"
                      v-model="registerForm.plainPasswordTwo"
                      :error-array="formValidation.plainPasswordTwo"
                      type="password"
                      :label="$t('registrationModal.passwordCheck')"
                      id="plainPasswordTwo"
                      name="new-password-two"
                      autocomplete="xqwerfvsdfsdc"/>

        <input type="hidden" name="_csrf_token" :value="csrfTokenAuthenticate">

        <input type="hidden" name="locale" :value="dateLocaleCode">

        <div class="col-span-4 sm:col-span-4 mt-4">
          <p class="text-gray-700 text-sm" v-html="$t('registrationModal.tAndCHtml')"/>
        </div>

        <div class="col-span-2 mt-4">
          <button :key="'registerButton'"
                  type="button"
                  @click="postRegisterForm"
                  class="bg-pg-yellow border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium hover:bg-pg-yellow-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pg-yellow-light">
            {{ $t('registrationModal.button') }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>


<script>
import CommonInput from './CommonInput'
import {mapState} from 'pinia';
import {useStore} from '../js/store';

export default {
  name: "RegistrationModal",

  props: ['csrfTokenAuthenticate'],

  data: function () {
    return {
      validated: false,
      loginEmail: '',
      loginPass: '',
      emailError: null,
      passError: null,
      registerForm: {
        firstName: '',
        lastName: '',
        email: '',
        plainPasswordOne: '',
        plainPasswordTwo: '',
        phoneNumber: '',
      },
      formValidation: {}
    }
  },
  computed: {
    ...mapState(useStore, {
      dateLocaleCode: 'dateLocaleCode'
    }),
  },
  methods: {
    blurEmail() {
    },
    blurPass() {
    },
    postRegisterForm() {
      let registerForm = { ...this.registerForm, locale: this.dateLocaleCode }
      this.$http.post('pre-validate-registration', registerForm)
          .then(() => {
            this.$refs.regform.submit()
          })
          .catch(error => {
            if (error.response.data.formValidation) {
              this.formValidation = error.response.data.formValidation
            }
          })
    }
  },
  components: {
    CommonInput
  }
}
</script>

<style scoped>

</style>
