<template>
  <TransitionRoot appear :show="isOpened" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-50">
<!--    <Dialog as="div" @close="closeModal" class="fixed inset-0 z-50 flex justify-center items-center">-->
      <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto p-4 justify-center items-center">
<!--      <div class="fixed inset-0 z-50 flex justify-center items-center">-->
        <div class="flex items-center justify-center w-full mx-0" :class="modalClass">
<!--             :class="{ 'w-full mx-10' }">-->
<!--             :class="{ 'w-full mx-10': size === 'full', 'w-full mx-10 lg:mx-0 xl:w-1/2': size === 'medium', 'w-full mx-10 md:mx-0 md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5': size === 'small' }">-->
          <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95">
            <DialogPanel class="relative w-full max-w-2xl min-h-full transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <div class="mt-3 mr-3 items-center absolute top-0 right-0 z-50">
                  <slot name="customCornerClose">
                    <button type="button"
                            v-if="showClose === true"
                            key="sidePanelCloseButton"
                            class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                            @click="closeModal">
                      <span class="sr-only">{{ $t('modal.buttonClose') }}</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true"/>
                    </button>
                  </slot>
              </div>
              <div class="">
                <slot>

                </slot>
              </div>

              <div class="flex justify-center mt-6" v-if="showClose === true && showCloseButton === true">
                <button @click="closeModal"
                        class="inline-flex items-center py-2 px-4 text-xs md:text-sm font-medium text-center text-gray-900 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200">
                  {{ $t('modal.buttonClose') }}
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
// import { InformationCircleIcon, LocationMarkerIcon, CameraIcon } from '@heroicons/vue/solid'
import { XMarkIcon } from '@heroicons/vue/24/outline'

import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  // DialogTitle,
} from '@headlessui/vue'

</script>

<script>
export default {
  props: {
    isOpened: {
      type: Boolean,
    },
    showClose: {
      type: Boolean,
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['full', 'medium', 'small'].includes(value)
      }
    },
    modalClass: {
      type: String,
      default: '',
    }
  },
  emits: ['closeModal'],
  data: function () {
    return {
      tab: 'about'
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    }
  }
}
</script>

<style scoped>
  .login-modal {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
</style>
