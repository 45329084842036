import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import './index.css'
import axios from 'axios'

import VueCookies from 'vue-cookies'

// import API from './js/api'
// createApp.prototype.$http = API
import cookieConsentSetting from './js/cookies.js'
import { createGtm } from '@gtm-support/vue-gtm';
// import { createI18n } from 'vue-i18n'

import i18n from './i18n'
import router from './router'
import mitt from 'mitt'
const emitter = mitt()
import SweetAlertIcons from 'vue-sweetalert-icons'
// For Vue v3, you also need to import styles explicitly:
import 'vue-sweetalert-icons/dist/style.css'

import ComgateCheckout from '@comgate/checkout'
// styly používané pro Checkout SDK komponenty
import '@comgate/checkout/lib/comgate-checkout.css'

// import VueDayjs from 'vue3-dayjs-plugin'
import dayjs from 'dayjs'

require('dayjs/locale/cs')
let customParseFormat = require('dayjs/plugin/customParseFormat')
let timezone = require('dayjs/plugin/timezone')
let utc = require('dayjs/plugin/utc')

import './assets/css/cookieconsent.css'
import 'vanilla-cookieconsent/dist/cookieconsent'

let axiosParams = {
    baseURL: '/'
}
if (process.env.NODE_ENV !== 'production') {
    let urlParts = window.location.href.split(':');
    axiosParams.baseURL = urlParts.slice(0, 2).join(':') + '/pg-master/public'
    axiosParams.allowCredentials = true
}

const axiosInstance = axios.create(axiosParams)

const app = createApp({
    extends: App,
    mounted() {
        this.$cookieConsent.run(cookieConsentSetting)
    }
})

app.use(createPinia())
app.config.globalProperties.emitter = emitter

app.use(
    createGtm({
        id: 'GTM-MRVBGQ6',
        defer: false,
        compatibility: false,
        nonce: '2726c7f26c',
        enabled: true,
        debug: true,
        loadScript: true,
        trackOnNextTick: false,
    }),
)

app.use(SweetAlertIcons)
i18n.setup()

app.use(i18n.vueI18n)

app.use(router)
app.use(VueCookies)

app.config.globalProperties.$http = { ...axiosInstance }
app.config.globalProperties.$cookieConsent = window.initCookieConsent()
// app.config.globalProperties.$cookieConsent.run(cookieConsentSetting)
app.config.globalProperties.$comgateCheckout = ComgateCheckout

app.config.globalProperties.$date = dayjs
app.config.globalProperties.$date.extend(customParseFormat)
app.config.globalProperties.$date.extend(utc)
app.config.globalProperties.$date.extend(timezone)

app.mount('#app')
