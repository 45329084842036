<template>
  <div>
    <label for="email_address" class="block text-sm font-medium text-gray-700" :class="{'text-red-500': this.errorArray}">{{ label }}</label>
    <input :value="modelValue"
           ref="input"
           @input="$emit('update:modelValue', $event.target.value)"
           @blur="$emit('blur')"
           :type="type"
           :name="name"
           :id="id"
           :autocomplete="autocomplete"
           class="mt-1 focus:ring-pg-yellow focus:border-pg-yellow block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
           :class="{'border-red-300': this.errorArray, 'bg-gray-50 border-gray-50 cursor-not-allowed': this.disabled }"/>
    <p class="text-red-500 text-sm" v-if="typeof errorArray === 'string'">
      {{ errorArray }}
    </p>
    <p class="text-red-500 text-sm" v-else-if="errorArray">
      <span v-for="error in errorArray" :key="error">{{ $t(error) }}</span>
    </p>
    <p class="text-gray-500 text-sm" v-if="note">{{ note }}</p>
<!--    {{ value }}-->
  </div>
</template>

<script>

export default {
  name: "CommonInput",
  props: ['modelValue', 'type', 'errorArray', 'note', 'label', 'name', 'id', 'autocomplete', 'autofocus', 'disabled'],
  emits: ['update:modelValue', 'blur'],
  mounted: function () {
    this.$nextTick(function () {
      if (this.autofocus === true) {
        this.$refs.input.focus()
      }
    })
  }
}
</script>

<style scoped>

</style>
