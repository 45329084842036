<template>
  <div class="text-right">
    <Menu as="div" class="relative inline-block text-left">
<!--        <MenuButton class="flex ml-2 text-gray-600 hover:text-gray-900 px-3 py-2 text-sm font-medium inline-block">-->
        <MenuButton class="flex ml-2 text-gray-600 hover:text-gray-900 p-0.5 pt-1.5 border-gray-500 text-sm font-medium inline-block">
          <img class="max-h-5 rounded-full" :alt="languages[currentLang].name" :src="getFlagImgUrl(languages[currentLang].flag)"/>
<!--          <ChevronDownIcon class="ml-1 h-5 w-5 hover:color-violet-100 color-gray-500 hover:text-gray-900" aria-hidden="true"/>-->
        </MenuButton>

      <transition :show="false"
                  appear
                  enter-active-class="transition duration-100 ease-out"
                  enter-from-class="transform scale-95 opacity-0"
                  enter-to-class="transform scale-100 opacity-100"
                  leave-active-class="transition duration-75 ease-in"
                  leave-from-class="transform scale-100 opacity-100"
                  leave-to-class="transform scale-95 opacity-0">
        <MenuItems class="absolute right-0 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div v-for="l in languages" class="px-1 py-1" :key="l.code">
            <MenuItem v-slot="{ active }">
                <button @click="switchLanguageTo(l)"
                  :class="[
                  active ? 'bg-gray-100 text-black' : 'text-gray-900',
                  'group flex w-full items-center rounded-md px-2 py-2 text-sm',]">
                  <img class="max-h-4 mr-2 rounded-full" :alt="l.name" :src="getFlagImgUrl(l.flag)"/>
                  {{ l.name }}
                </button>

            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script setup>
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
// import { ChevronDownIcon } from '@heroicons/vue/24/outline'

</script>

<script>
import {mapActions, mapState} from 'pinia'
import { useStore } from '../js/store'

export default {
  data: function () {
    return {
      defaultLang: 'cs',
      languages: {
        cs: {
          code: '',
          name: 'česky',
          flag: 'cz',
          dateLocaleCode: 'cs'
        },
        en: {
          code: 'en',
          name: 'english',
          flag: 'gb',
          dateLocaleCode: 'en'
        }
      }
    }
  },
  computed: {
    currentLang() {
      return this.$i18n.locale
    },
    ...mapState(useStore, {
      dateLocaleCode: 'dateLocaleCode'
    }),
    ...mapState(useStore, {
      getUserLocaleCode: 'getUserLocaleCode'
    }),
    ...mapState(useStore, {
      userIsLoggedIn: 'userIsLoggedIn'
    }),
  },
  watch: {
    dateLocaleCode(val) {
      this.$cookieConsent.updateLanguage(val, true)
    },
    getUserLocaleCode(val) {
      if (this.userIsLoggedIn) {
        this.switchLanguageTo(this.language[val])
      }
    }
  },
  methods: {
    switchLanguageTo(lang) {
      this.$router.push({ params: { locale: lang.code } })
      this.setDateLocaleCode(lang.dateLocaleCode)
    },

    getFlagImgUrl(flag) {
      return require('../assets/img/flag/' + flag + '.svg')
    },

    ...mapActions(useStore, {setDateLocaleCode: 'setDateLocaleCode'}),
  },
  mounted() {
  }
}
</script>
