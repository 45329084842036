export default {
    revision: 1,
    page_scripts: true,
    current_lang : 'cs',
    force_consent: true,
    cookie_domain: '.privategym.cz',
    gui_options: {
        consent_modal: {
            layout: 'box',               // box/cloud/bar
            position: 'middle center',     // bottom/middle/top + left/right/center
            transition: 'slide',           // zoom/slide
            swap_buttons: false            // enable to invert buttons
        },
        settings_modal: {
            layout: 'box',                 // box/bar
            transition: 'slide'            // zoom/slide
        }
    },
    languages: {
        'cs': {
            consent_modal: {
                title: 'Tato webová stránka používá cookies',
                description: 'Pokud cookies přijmete, pomůžete nám se zlepšováním služeb, webu a rezervační webové aplikace. Své rozhodnutí můžete kdykoli změnit.',
                primary_btn: {
                    text: 'Přijmout vše',
                    role: 'accept_all'      // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Nastavení',
                    role: 'settings'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Pokročilé nastavení cookies',
                save_settings_btn: 'Uložit nastavení',
                accept_all_btn: 'Přijmout vše',
                cookie_table_headers: [
                    {col1: 'Název'},
                    {col2: 'Typ'},
                    {col3: 'Vypršení'},
                    {col4: 'Popis'}
                ],
                blocks: [
                    {
                        title: 'Nutná',
                        description: 'Nutné cookies pomáhají, aby byla webová stránka použitelná tak, že umožní základní funkce jako navigace stránky a přístup k zabezpečeným sekcím webové stránky. Webová stránka nemůže správně fungovat bez těchto cookies.',
                        // description: 'Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.',
                        toggle: {
                            value: 'technical',
                            enabled: true,
                            readonly: true
                        },
                        cookie_table: [
                            {
                                col1: 'privategym-res',
                                col2: 'HTTP',
                                col3: '15 minut',
                                col4: 'Slouží k identifikaci rozpracované rezervace.'
                            }
                        ]
                    },
                    {
                        title: 'Statistické',
                        description: 'Statistické cookies pomáhají majitelům webových stránek, aby porozuměli, jak návštěvníci používají webové stránky. Anonymně sbírají a sdělují informace.',
                        // description: 'Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.',
                        toggle: {
                            value: 'analytics',
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: '_ga',
                                col2: 'HTTP',
                                col3: '2 roky',
                                col4: 'Registruje jedinečné ID, které se používá ke generování statistických údajů o tom, jak návštěvník používá webovou stránku.'
                                // col4: 'Registers a unique ID that is used to generate statistical data on how the visitor uses the website.'
                            },
                            {
                                col1: '_ga_KHEYTSQJM2',
                                col2: 'HTTP',
                                col3: '2 roky',
                                col4: 'Používá Google Analytics ke shromažďování údajů o tom, kolikrát uživatel navštívil web, a také o datech první a poslední návštěvy.'
                                // col4: 'Used by Google Analytics to collect data on the number of times a user has visited the website as well as dates for the first and most recent visit.'
                            },
                            {
                                col1: '_gid',
                                col2: 'HTTP',
                                col3: '1 den',
                                col4: 'Soubor cookie _gid nainstalovaný službou Google Analytics ukládá informace o tom, jak návštěvníci používají webovou stránku, a zároveň vytváří analytickou zprávu o výkonu webu. Některá data, která jsou shromažďována, zahrnují počet návštěvníků, jejich zdroj a stránky, které anonymně navštěvují.'
                                // col4: 'Installed by Google Analytics, _gid cookie stores information on how visitors use a website, while also creating an analytics report of the website's performance. Some of the data that are collected include the number of visitors, their source, and the pages they visit anonymously.'
                            },
                            {
                                col1: '_gat_UA-204462063-1',
                                col2: 'HTTP',
                                col3: '1 minuta',
                                col4: 'Varianta souboru cookie _gat nastaveného službami Google Analytics a Google Tag Manager, který umožňuje vlastníkům webových stránek sledovat chování návštěvníků a měřit výkon webu. Prvek vzoru v názvu obsahuje jedinečné identifikační číslo účtu nebo webové stránky, ke kterému se vztahuje.'
                                // col4: 'A variation of the _gat cookie set by Google Analytics and Google Tag Manager to allow website owners to track visitor behaviour and measure site performance. The pattern element in the name contains the unique identity number of the account or website it relates to.'
                            }
                        ]
                    }
                ]
            }
        },
        'en': {
            'consent_modal': {
                'title': 'This website uses cookies',
                'description': 'If you accept cookies, you will help us to improve services, website, and reservation web application. You can change your decision at any time.',
                'primary_btn': {
                    'text': 'Accept all',
                    'role': 'accept_all' // 'accept_selected' or 'accept_all'
                },
                'secondary_btn': {
                    'text': 'Settings',
                    'role': 'settings' // 'settings' or 'accept_necessary'
                }
            },
            'settings_modal': {
                'title': 'Advanced cookie settings',
                'save_settings_btn': 'Save settings',
                'accept_all_btn': 'Accept all',
                'cookie_table_headers': [
                    {'col1': 'Name'},
                    {'col2': 'Type'},
                    {'col3': 'Expiration'},
                    {'col4': 'Description'}
                ],
                'blocks': [
                    {
                        'title': 'Necessary',
                        'description': 'Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.',
                        'toggle': {
                            'value': 'technical',
                            'enabled': true,
                            'readonly': true
                        },
                        'cookie_table': [
                            {
                                'col1': 'privategym-res',
                                'col2': 'HTTP',
                                'col3': '15 minutes',
                                'col4': 'Used to identify an in-progress reservation.'
                            }
                        ]
                    },
                    {
                        'title': 'Statistical',
                        'description': 'Statistical cookies help website owners understand how visitors use their website. They anonymously collect and share information.',
                        'toggle': {
                            'value': 'analytics',
                            'enabled': false,
                            'readonly': false
                        },
                        'cookie_table': [
                            {
                                'col1': '_ga',
                                'col2': 'HTTP',
                                'col3': '2 years',
                                'col4': 'Registers a unique ID that is used to generate statistical data on how the visitor uses the website.'
                            },
                            {
                                'col1': '_ga_KHEYTSQJM2',
                                'col2': 'HTTP',
                                'col3': '2 years',
                                'col4': 'Used by Google Analytics to collect data on the number of times a user has visited the website, as well as data for the first and most recent visit.'
                            },
                            {
                                'col1': '_gid',
                                'col2': 'HTTP',
                                'col3': '1 day',
                                'col4': 'Installed by Google Analytics, the _gid cookie stores information on how visitors use a website and also creates an analytics report on the website\'s performance. Some of the data collected includes the number of visitors, their source, and the pages they visit anonymously.'
                            },
                            {
                                'col1': '_gat_UA-204462063-1',
                                'col2': 'HTTP',
                                'col3': '1 minute',
                                'col4': 'A variant of the _gat cookie set by Google Analytics and Google Tag Manager that allows website owners to track visitor behavior and measure site performance. The pattern element in the name contains the unique identification number of the account or website to which it relates.'
                            }
                        ]
                    }
                ]
            }
        }
    }
}
