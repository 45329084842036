<template>
  <div ref="appElement">
<!--    <MainComponent :reservation="reservation" :rebook-reservation="rebookReservation" :csrf-token-authenticate="csrfTokenAuthenticate" @updateUserData="updateUserData"/>-->
    <MainComponent :reservation="reservation" :rebook-reservation="rebookReservation" :csrf-token-authenticate="csrfTokenAuthenticate"/>
  </div>
</template>

<script>
import MainComponent from './components/MainComponent.vue'
import { mapActions, mapState } from 'pinia'
import { useStore } from './js/store'
// import { useStorage } from '@vueuse/core'

export default {
  name: 'App',
  data: function () {
    return {
      reservation: null,
      rebookReservation: null,
      csrfTokenAuthenticate: '',
      // userData: {},
    }
  },
  components: {
    MainComponent
  },
  computed: {
    ...mapState(useStore, {
      selectedSlots: 'selectedSlots'
    }),
    ...mapState(useStore, {
      getSelectedSlotIds: 'getSelectedSlotIds'
    }),
    ...mapState(useStore, {
      dateLocaleCode: 'dateLocaleCode'
    }),

    // locale() {
    //   if (this.$route.local === 'en') {
    //     return 'en'
    //   } else {
    //     return 'cs'
    //   }
    // },
  },
  watch: {
    dateLocaleCode: function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.$date.locale(newVal)
      }
    },
  },
  mounted() {
    this.$date.locale(this.dateLocaleCode)
    this.updateSelectedSlotsAvailability()

    // storage.value.forEach()

    // let nowWIthoutTz = this.$moment()
    // let now = this.$moment().tz('Europe/Prague')

    // // this.reservation = {id: 64}
    let reservation = {}

    // reservation = {uuid: '4e492da6-8f69-4f7d-b261-4cb1594a9ae5'}

    // reservation = {rebook: true, uuid: '90557182-b15a-4f27-8d68-ac8172f7cb61', rebookSlotId: 4908}

    // this.csrfTokenAuthenticate = this.$parent.$el.attributes['data-csrf-t'].value

    // TODO
    let userData = {}

    if (this.$refs.appElement && this.$refs.appElement.parentNode && this.$refs.appElement.parentNode.attributes['data-reservation']) {
      reservation = JSON.parse(this.$refs.appElement.parentNode.attributes['data-reservation'].value)
    }

    if (this.$refs.appElement && this.$refs.appElement.parentNode && this.$refs.appElement.parentNode.attributes['data-csrf-t']) {
      this.csrfTokenAuthenticate = this.$refs.appElement.parentNode.attributes['data-csrf-t'].value
    }

    if (this.$refs.appElement && this.$refs.appElement.parentNode && this.$refs.appElement.parentNode.attributes['data-user']) {
      userData = JSON.parse(this.$refs.appElement.parentNode.attributes['data-user'].value)
      this.setUserAndUpdateLocale(userData)
    } else {
      userData = {}
    }

    // userData = {
    //   "userEmail": "kuba77.zeli@seznam.cz",
    //   "userFirstName": "Jakub",
    //   "userLastName": "Zelenka",
    //   "userPhoneNumber": "+420739303399",
    //   "userPhoneVerified": true,
    //   "userEmailVerified": true,
    //   "locale": "cs",
    //   "roles": [
    //     "ROLE_USER"
    //   ]
    // }
    // this.setUserAndUpdateLocale(userData)

    // this.setUser(userData)

    if (reservation && Object.keys(reservation).length > 0 && reservation.rebook === true) {
      this.rebookReservation = reservation

      this.getReservationSlotRebookInfo()

    } else if (reservation && Object.keys(reservation).length > 0) {
      this.updateLocale(reservation.locale)
      this.setReservation(reservation)
      // this.reservation = reservation
    }
  },
  methods: {
    updateUserData(userData) {
      // this.userData = userData
      this.setUser(userData)
    },

    updateLocale(locale) {
      // this.$router.push({ params: { locale: locale } })
      this.setDateLocaleCode(locale)
    },

    setUserAndUpdateLocale(userData) {
      this.setUser(userData)
      if (userData.locale) {
        this.updateLocale(userData.locale)
      }
    },

    updateSelectedSlotsAvailability() {
      if (this.getSelectedSlotIds.length > 0) {
        this.$http.post('slots/availability-check', {slotIds: this.getSelectedSlotIds })
            .then(response => {
              this.filterSelectedSlotsByIds(response.data)
            })
            .catch(() => {
            })
      }
    },

      getReservationSlotRebookInfo() {
          // this.loadingReservation = true
          this.$http.get('reservation/' + this.rebookReservation.uuid + '/' + 's/' + this.rebookReservation.rebookSlotId)
              .then(response => {
                  this.setRebookInfo(response.data)
              })
              .catch(() => {
              })
      },

    ...mapActions(useStore, {setUser: 'setUser'}),
    ...mapActions(useStore, {filterSelectedSlotsByIds: 'filterSelectedSlotsByIds'}),

    ...mapActions(useStore, {setDateLocaleCode: 'setDateLocaleCode'}),

    ...mapActions(useStore, {setRebookInfo: 'setRebookInfo'}),
    ...mapActions(useStore, {setReservation: 'setReservation'}),
  },
  updated() {}
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap');

#app {
  font-family: 'Inter', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  /*color: #2c3e50;*/
}
</style>
