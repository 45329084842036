<template>
  <TransitionRoot appear :show="isOpened" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-50">
      <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto p-4">
        <div class="flex">
          <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95">
            <DialogPanel class="w-full min-h-full transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <div class="mt-6 mr-6 items-center absolute top-0 right-0 z-50">
                <button type="button"
                        key="sidePanelCloseButton"
                        class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                        @click="closeModal">
                  <span class="sr-only">{{ $t('branchModal.closeButton') }}</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true"/>
                </button>
              </div>
              <DialogTitle class="">{{ branch.name }}, {{ branch.district }}</DialogTitle>
              <div class="">
                <div class="lg:hidden border-b border-gray-200">
                  <nav class="-mb-px flex" aria-label="Tabs">
                    <button key="tabAbout" @click="tab = 'about'" :class="[tab === 'about' ? 'border-ps-yellow-3 text-gray-900' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'w-1/3 py-4 px-1 text-center border-b-2 font-medium text-sm']" :aria-current="tab">
                      <InformationCircleIcon class="inline-block -ml-0.5 mr-2 h-5 w-5" aria-hidden="true"/>
                      {{ $t('branchModal.tabButton.equipment') }}
                    </button>
                    <button key="tabPlace" @click="tab = 'place'" :class="[tab === 'place' ? 'border-ps-yellow-3 text-gray-900' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'w-1/3 py-4 px-1 text-center border-b-2 font-medium text-sm']" :aria-current="tab">
                      <MapPinIcon class="inline-block -ml-0.5 mr-2 h-5 w-5" aria-hidden="true"/>
                      {{ $t('branchModal.tabButton.place') }}
                    </button>
                    <button key="tabAbout" @click="tab = 'gallery'" :class="[tab === 'gallery' ? 'border-ps-yellow-3 text-gray-900' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'w-1/3 py-4 px-1 text-center border-b-2 font-medium text-sm']" :aria-current="tab">
                      <CameraIcon class="inline-block -ml-0.5 mr-2 h-5 w-5" aria-hidden="true"/>
                      {{ $t('branchModal.tabButton.gallery') }}
                    </button>
                  </nav>
                </div>
                <div class="grid grid-cols-12 lg:gap-8">
                  <div class="" :class="[tab === 'about' ? 'col-span-12 lg:col-span-6 xl:col-span-7' : 'hidden lg:block lg:col-span-6 xl:col-span-7']">
                    <p class="mt-3 text-sm text-gray-500">
                      {{ branchInfoText.about.intro }}
                    </p>
                    <ul class="p-0 pt-2 m-0 leading-6 border-0 border-gray-300" v-if="branchInfoText.about.basics">
                      <li v-for="(basic, index) in branchInfoText.about.basics" :key="index" class="box-border relative py-0 pl-0 text-left text-gray-500 border-solid">
                        <div class="relative flex space-x-3">
                          <div>
                        <span class="h-8 w-8 flex items-center justify-center">
                            <svg class="h-5 w-5 text-gray-900" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path>
                            </svg>
                        </span>
                          </div>
                          <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                            <div>
                              <p class="text-sm text-gray-500">{{ basic.what }}</p>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <h4 class="text-md leading-6 mt-3 font-medium text-gray-900" v-if="branchInfoText.about.exerciseEquipment">
                      {{ $t('branchModal.title.exerciseEquipment') }}
                    </h4>
                    <ul class="p-0 m-0 leading-6 border-0 border-gray-300">
                      <li v-for="(equipment, index) in branchInfoText.about.exerciseEquipment" :key="index" class="box-border relative py-0 pl-0 text-left text-gray-500 border-solid">
                        <div class="relative flex space-x-3">
                          <div>
                            <span class="h-8 w-8 flex items-center justify-center">
                                <svg class="h-5 w-5 text-gray-900" fill="none" stroke="currentColor" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                  <g stroke="#000" stroke-linejoin="round" stroke-width="4"><path d="m14 13.6c0-1.4359-1.3431-2.6-3-2.6-1.65685 0-3 1.1641-3 2.6v20.8c0 1.4359 1.34315 2.6 3 2.6 1.6569 0 3-1.1641 3-2.6z"/><path d="m40 13.6c0-1.4359-1.3431-2.6-3-2.6s-3 1.1641-3 2.6v20.8c0 1.4359 1.3431 2.6 3 2.6s3-1.1641 3-2.6z"/><path d="m8 18.6667c0-1.4728-1.34315-2.6667-3-2.6667s-3 1.1939-3 2.6667v10.6666c0 1.4728 1.34315 2.6667 3 2.6667s3-1.1939 3-2.6667z"/><path d="m46 18.6667c0-1.4728-1.3431-2.6667-3-2.6667s-3 1.1939-3 2.6667v10.6666c0 1.4728 1.3431 2.6667 3 2.6667s3-1.1939 3-2.6667z"/><path d="m0 0h20v6h-20z" transform="matrix(1 0 0 -1 14 27)"/></g>
                                </svg>
                            </span>
                          </div>
                          <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                            <div>
                              <p class="text-sm text-gray-500">{{ equipment.what }} <span class="px-2 py-0.5 text-xs font-bold bg-yellow-300 text-black rounded" v-if="equipment.new">{{ $t('branchModal.label.new') }}</span></p>
                            </div>

                          </div>
                        </div>
                      </li>
                    </ul>
                    <h4 class="text-md leading-6 mt-3 font-medium text-gray-900">
                      {{ $t('branchModal.title.additionalEquipment') }}
                    </h4>
                    <ul class="p-0 m-0 leading-6 border-0 border-gray-300">
                      <li v-for="(equipment, index) in branchInfoText.about.otherEquipment" :key="index" class="box-border relative py-0 pl-0 text-left text-gray-500 border-solid">
                        <div class="relative flex space-x-3">
                          <div>
                            <HandThumbUpIcon class="inline-block ml-2 h-5 w-5" aria-hidden="true"/>
                          </div>
                          <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                            <div>
                              <p class="text-sm text-gray-500">{{ equipment.what }}</p>
                            </div>

                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="" :class="[tab === 'place' ? 'col-span-12 lg:col-span-6 xl:col-span-5' : 'hidden lg:block lg:col-span-6 xl:col-span-5 lg:border-l lg:pl-8']">
                    <p class="mt-3 text-sm text-gray-500 pb-2">
                      {{ $t('branchModal.title.youCanFindGymAtTheAddress') }} <a :href="branchInfoText.place.googleMapsLink">{{ branchInfoText.place.fullAddress }}</a>
                    </p>
                    <div>
                      <iframe :src="branchInfoText.place.googleMapsEmbedLink"
                              style="border:0; overflow:hidden; height: 350px; width:100%"
                              allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                    <h4 class="text-lg leading-6 mt-3 font-medium text-gray-900" v-if="branchInfoText.about.exerciseEquipment">
                      {{ $t('branchModal.title.howToGetThere') }}
                    </h4>
                    <h4 class="text-md leading-6 mt-3 font-medium text-gray-900" v-if="branchInfoText.about.exerciseEquipment">
                      {{ $t('branchModal.title.publicTransport') }}
                    </h4>
                    <p class="mt-3 text-sm text-gray-500 pb-2">
                      {{ branchInfoText.place.howToGetThere.publicTransport }}
                    </p>
                    <h4 class="text-md leading-6 mt-3 font-medium text-gray-900" v-if="branchInfoText.about.exerciseEquipment">
                      {{ $t('branchModal.title.car') }}
                    </h4>
                    <p class="mt-3 text-sm text-gray-500 pb-2">
                      {{ branchInfoText.place.howToGetThere.car }}
                    </p>
                  </div>
                  <div class="columns-1 md:columns-2 lg:columns-3 xl:columns-4 mt-6 lg:mt-0" :class="[tab === 'gallery' ? 'col-span-12' : 'hidden lg:block lg:col-span-12 lg:border-t lg:pt-8']">
                    <div v-for="(image, index) in branchInfoText.gallery.images" :key="index" class="w-full mb-4">
                      <img :src="image.src" :alt="image.alt" class="rounded-md">
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex justify-center mt-6">
                <button @click="closeModal" class="inline-flex items-center py-2 px-4 text-xs md:text-sm font-medium text-center text-gray-900 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200">{{ $t('branchModal.closeButton') }}</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { InformationCircleIcon, MapPinIcon, CameraIcon } from '@heroicons/vue/24/solid'
import { HandThumbUpIcon, XMarkIcon } from '@heroicons/vue/24/outline'

import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue'

</script>

<script>
export default {
  props: ['isOpened', 'branch'],
  data: function () {
    return {
      tab: 'about'
    }
  },
  emits: ['closeModal'],
  methods: {
    closeModal() {
      this.$emit('closeModal')
    }
  },
  computed: {
    branchInfoText() {
      return this.branch.infoText[this.$i18n.locale];
    },
  }
}
</script>
