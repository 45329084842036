import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withKeys as _withKeys, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-center mt-4 mb-2" }
const _hoisted_2 = ["onUpdate:modelValue", "disabled", "onFocus", "onKeydown"]
const _hoisted_3 = {
  key: 0,
  class: "text-red-500 text-sm text-center"
}
const _hoisted_4 = {
  key: 1,
  class: "text-red-500 text-sm text-center"
}
const _hoisted_5 = {
  key: 2,
  class: "text-gray-500 text-sm text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cells, (cell, index) => {
        return _withDirectives((_openBlock(), _createElementBlock("input", {
          key: cell.key,
          ref_for: true,
          ref: `${$props.baseRefName}${index}`,
          "onUpdate:modelValue": ($event: any) => ((cell.value) = $event),
          class: _normalizeClass(["h-14 w-10 mx-1 md:h-16 md:w-12 border md:mx-2 rounded-lg flex items-center text-center font-thin text-2xl md:text-3xl focus:border-pg-yellow", { 'cursor-not-allowed bg-gray-100 text-gray-600': $props.disabled, 'border-red-300': this.errorArray }]),
          type: "tel",
          disabled: $props.disabled,
          onFocus: ($event: any) => (_ctx.focusedCellIdx = index),
          onKeydown: [
            _withKeys(($event: any) => ($options.onCellErase(index, $event)), ["delete"]),
            _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onKeyDown && $options.onKeyDown(...args)))
          ]
        }, null, 42, _hoisted_2)), [
          [_vModelText, cell.value]
        ])
      }), 128))
    ]),
    (typeof $props.errorArray === 'string')
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($props.errorArray), 1))
      : ($props.errorArray)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.errorArray, (error) => {
              return (_openBlock(), _createElementBlock("span", { key: error }, _toDisplayString(_ctx.$t(error)), 1))
            }), 128))
          ]))
        : _createCommentVNode("", true),
    ($props.note)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString($props.note), 1))
      : _createCommentVNode("", true)
  ], 64))
}