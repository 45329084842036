const gtmTrackMixin = {
    data() {
        return {
        }
    },
    computed: {
    },
    methods: {
        trackNextStep(step) {
            if (step === 2) {
                this.$gtm.trackEvent({
                    event: 'go_to_form',
                    category: 'Booking',
                    action: 'click',
                    label: 'go_to_form'
                })
            }
        },
    }
}

export default gtmTrackMixin
