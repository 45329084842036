import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const useStore = defineStore('store', {
    state: () => ({
        // reservationStep: useStorage('reservationStep', 3),
        reservationStep: 0,
        reservationMobileInterStep: false,
        selectedSlots: useStorage('selectedSlots', []),
        selectedAutomaticReservations: useStorage('selectedAutomaticReservations', []),
        selectedGiftCards: useStorage('selectedGiftCards', []),
        selectedBranch: useStorage('selectedBranch', {}),
        contact: useStorage('contact', {
            firstName: '',
            lastName: '',
            phone: '+420',
            email: '',
            terms: true,
            personalData: true,
            locale: 'cs'
        }),
        discountCode: useStorage('discountCode', {}),
        voucherCode: useStorage('voucherCode', {}),
        user: useStorage('user', {}),
        userRoles: [],
        userIsLoggedIn: false,
        rebookSlots: [],
        rebookSummary: {},

        reservation: null,

        dateLocaleCode: 'cs',

        totalOrderPrice: 0,

        // reservationStep: 0,
        // selectedSlots: [],
    }),
    // getters: {
    //     getReservationStep(state) {
    //         return state.reservationStep
    //     },
    // },
    getters: {
        getSelectedSlotIds() {
            return this.selectedSlots.map(a => a.id)
        },
        getUserLocaleCode() {
            return this.contact.locale
        },
    },
    actions: {
        setRebookInfo(info) {
            this.rebookSlots = info.slots
            this.rebookSummary = info
        },
        addSlot(slot) {
            this.selectedSlots.push(slot)
        },
        removeAutomaticReservationFromList(index) {
            this.selectedAutomaticReservations.splice(index, 1)
        },
        addGiftVoucherToCart(giftCard) {
            this.selectedGiftCards.push(giftCard)
        },
        removeSlot(slot) {
            let slotId = slot.id
            this.selectedSlots.splice(this.selectedSlots.findIndex(slot => slot.id === slotId), 1)
        },
        removeGiftCard(giftCardIndex) {
            this.selectedGiftCards.splice(giftCardIndex, 1)
        },
        clearSelectedAndRebookSlots() {
            this.selectedGiftCards = []
            this.selectedSlots = []
            this.selectedAutomaticReservations = []
            this.rebookSlots = []
        },
        setDiscountCode(code) {
            this.discountCode = code
        },
        setVoucherCode(code) {
            this.voucherCode = code
        },
        removeDiscountCode() {
            this.discountCode = null
            this.voucherCode = null
        },
        setReservationStep(step) {
            this.reservationStep = step
        },
        showReservationMobileInterStep() {
            this.reservationMobileInterStep = true
        },
        hideReservationMobileInterStep() {
            this.reservationMobileInterStep = false
            if (this.selectedBranch === null || Object.keys(this.selectedBranch).length === 0) {
                this.setReservationStep(0)
            }
        },
        setSelectedBranch(branch) {
            this.selectedBranch = branch
        },
        setContactKeyValue(key, value) {
            this.contact[key] = value
        },
        setReservation(reservation) {
            this.reservation = reservation
        },
        setDateLocaleCode(locale) {
            this.dateLocaleCode = locale
            this.contact.locale = locale
        },
        setTotalOrderPrice(price) {
            this.totalOrderPrice = price
        },
        setUser(user) {
            this.user = user
            this.userIsLoggedIn = false
            if (user.userEmail) {
                this.contact.email = user.userEmail
                this.contact.firstName = user.userFirstName
                this.contact.lastName = user.userLastName
                this.contact.phone = user.userPhoneNumber
                this.userIsLoggedIn = true
                this.userRoles = user.roles
            }
        },
        filterSelectedSlotsByIds(slotIds) {
            this.selectedSlots = this.selectedSlots.filter(function(slot) {
                return slotIds.includes(slot.id) === true
            })
        },
        logoutUser() {
            this.user = {}
            this.userIsLoggedIn = false
            this.userRoles = []
        },
        // setMouseAction(action) {
        //     this.mouseAction = action
        // },
    },
})
