<template>
  <div class="md:col-span-1">
    <div class="text-sm leading-6 font-medium text-gray-900 block md:hidden flex items-center pb-2 justify-between" v-if="showBackButton === true">
      <button class="inline-flex items-center shadow-sm px-1 py-1 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50" @click="hideReservationMobileInterStep()">
        <ChevronLeftIcon class="h-5 w-5" aria-hidden="true"/>
      </button>
      <h3 class="inline-block text-lg font-medium leading-6 text-gray-900">{{ $t('selectedSlots.cart') }}</h3>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
    </div>

    <h3 v-else class="text-lg font-medium leading-6 text-gray-900 pb-2">{{ $t('selectedSlots.cart') }}</h3>

    <div v-if="selectedGiftCards.length > 0" class="">
      <div class="flow-root">
        <ul class="divide-y divide-gray-200">
          <li v-for="(giftCard, index) in selectedGiftCards" :key="index + 'sgc'" class="pt-1 pb-2">
            <div class="flex items-center space-x-1">
              <GiftIcon class="h-3 w-3 mb-0.5 inline-block" aria-hidden="true"/>
              <p class="text-sm flex-1 text-gray-900 truncate">
                {{ $t('selectedSlots.giftVoucherPrivateGym') }}
              </p>
              <div class="text-sm text-gray-900">
                    {{ giftCard.price }} {{ $t('czk') }}
              </div>
              <div class="flex-shrink-0" v-if="step === 0 || step === 1">
                <button @click="removeGiftCard(index)"
                        class="ml-1 inline-flex items-center shadow-sm px-0.5 py-0.5 border border-gray-300 text-xs font-medium rounded-full text-gray-700 bg-white hover:bg-red-50 hover:border-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                  <XMarkIcon class="h-3 w-3 inline-block" aria-hidden="true"/>
<!--                  {{ $t('selectedSlots.remove') }}-->
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <template v-if="slotsToRebook.length > 0">
      <h3 class="text-sm font-medium text-red-600">{{ $t('selectedSlots.termsToCancel') }}</h3>
      <div class="flow-root pb-2">
        <ul class="divide-y divide-gray-200">
          <li v-for="day in resorted(slotsToRebook)" :key="day.date + 'strn'" class="pt-1 pb-1">
            <div class="flex items-center space-x-2">
              <div class="flex-1 min-w-0">
                <p class="text-sm font-medium text-red-600 truncate">
                  {{ (day.date).split('.').join('. ') }} &nbsp;&nbsp;<span class="text-red-600">{{ $date(day.date, 'D.M.YYYY').format('dddd') }}</span>
                </p>
                <div v-for="slot in day.slots" :key="slot.id" class="flex items-center space-x-2 mb-1 last:mb-0">
                  <template v-if="slot.isAllowedToRebook === true">
                    <div class="flex-1 min-w-0">
                      <p class="text-sm text-red-600 truncate">
                        {{ slot.from }} - {{ slot.to }}<br>
                      </p>
                      <p class="text-xs text-red-600 truncate">
                        {{ slot.branch }}<br>
                      </p>
                    </div>
                    <div class="text-red-600">
                      -{{ slot.price }} {{ $t('czk') }}
                    </div>
                  </template>
                  <template v-else>
                    <div class="flex-1 min-w-0">
                      <p class="text-sm text-red-600">
                        {{ slot.from }} - {{ slot.to }}<br>
                      </p>
                      <p class="text-xs text-red-600 truncate">
                        {{ slot.branch }}<br>
                      </p>
                    </div>
                    <div class="text-red-600">
                      {{ $t('selectedSlots.cantChange') }}
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </template>
    <template v-if="selectedAutomaticReservations.length > 0">
      <h3 class="text-sm font-medium text-gray-900">{{ $t('selectedSlots.automaticReservations') }}</h3>
      <div class="flow-root pb-2">
        <ul class="divide-y divide-gray-200">
          <li v-for="(automaticReservation, index) in selectedAutomaticReservations" :key="index + 'autoRes'">
            <div class="flex items-center space-x-2">
              <div class="flex-1 min-w-0">
                <div class="flex items-center space-x-2 mb-1 last:mb-0">
                  <div class="flex-1 min-w-0">
                    <p class="text-sm text-gray-500 truncate">
                      {{ automaticReservation.day.label }} <span class="text-gray-500">{{ automaticReservation.startDate.from }} - {{ automaticReservation.startDate.to }}</span>
                    </p>
                    <p class="text-xs text-gray-500 truncate">
                      {{ automaticReservation.branch.district }}, {{ automaticReservation.branch.name }}<br>
                    </p>
                  </div>
                  <div>
<!--                    {{ slot.price }} {{ $t('czk') }}-->
                  </div>
                  <div class="flex-shrink-0" v-if="step === 0 || step === 1">
                    <button @click="removeAutomaticReservationFromList(index)"
                            class="inline-flex items-center shadow-sm px-0.5 py-0.5 border border-gray-300 text-xs font-medium rounded-full text-gray-700 bg-white hover:bg-red-50 hover:border-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                      <XMarkIcon class="h-3 w-3 inline-block" aria-hidden="true"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </template>
<!--    <h3 class="text-lg font-medium leading-6 text-gray-900">{{ $t('selectedSlots.selectedSlots') }}</h3>-->
    <div class="flow-root">
      <ul class="divide-y divide-gray-200">
        <li v-for="day in resorted(selectedSlots)" :key="day.date" class="pt-1 pb-1">
          <div class="flex items-center space-x-2">
            <div class="flex-1 min-w-0">
              <p class="text-sm text-gray-900 truncate">
                {{ (day.date).split('.').join('. ') }} &nbsp;&nbsp;<span class="text-gray-500">{{ $date(day.date, 'D.M.YYYY').format('dddd') }}</span>
              </p>
              <div v-for="slot in day.slots" :key="slot.id" class="flex items-center space-x-2 mb-1 last:mb-0">
                <div class="flex-1 min-w-0">
                  <p class="text-sm text-gray-500 truncate">
                    {{ slot.from }} - {{ slot.to }} &nbsp;&nbsp;<span class="text-xs text-gray-500">{{ slot.branch }}</span>
                  </p>
                </div>
                <div>
                  {{ slot.price }} {{ $t('czk') }}
                </div>
                <div class="flex-shrink-0" v-if="step === 0 || step === 1">
                  <button @click="removeSlot(slot)"
                          class="inline-flex items-center shadow-sm px-0.5 py-0.5 border border-gray-300 text-xs font-medium rounded-full text-gray-700 bg-white hover:bg-red-50 hover:border-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                    <XMarkIcon class="h-3 w-3 inline-block" aria-hidden="true"/>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="py-4">
          <div class="flex items-center space-x-2" v-if="returnDiscount > 0 || returnTotalCodeDiscount > 0">
            <div class="flex-1 min-w-0">
              <p class="text-sm font-medium text-gray-900 truncate">
                {{ $t('selectedSlots.priceWithoutDiscount') }}
              </p>
            </div>
            <div>
              {{ returnTotalPrice }} {{ $t('czk') }}
            </div>
          </div>
          <div class="flex items-center space-x-2" v-if="returnDiscount > 0">
            <div class="flex-1 min-w-0">
              <p class="text-sm font-medium text-gray-900 truncate">
                {{ $t('selectedSlots.discountForMoreReservations', { discount: getPercentageAmountDiscount }) }}
              </p>
            </div>
            <div>
              {{ returnDiscount }} {{ $t('czk') }}
            </div>
          </div>
          <div class="flex items-center space-x-2" v-if="returnTotalCodeDiscount > 0">
            <div class="flex-1 min-w-0">
              <p class="text-sm font-medium text-gray-900 truncate">
                {{ $t('selectedSlots.discountCode') }}
              </p>
            </div>
            <div>
              {{ returnTotalCodeDiscount }} {{ $t('czk') }}
            </div>
          </div>
          <div class="flex items-center space-x-2" v-if="returnTotalVoucherCodeDiscount > 0">
            <div class="flex-1 min-w-0">
              <p class="text-sm font-medium text-gray-900 truncate">
                {{ $t('selectedSlots.forVoucherCode') }}
              </p>
            </div>
            <div>
              -{{ returnTotalVoucherCodeDiscount }} {{ $t('czk') }}
            </div>
          </div>
          <div class="flex items-center space-x-2 mt-3">
            <div class="flex-1 min-w-0">
              <p class="text-sm font-medium text-gray-900 truncate font-bold">
                {{ $t('selectedSlots.totalPrice') }}
              </p>
            </div>
            <div>
              <p class="font-bold">
                {{ returnGrandTotalPrice }} {{ $t('czk') }}
              </p>
            </div>
          </div>
          <template v-if="userData && userData.userPercentageDiscount && userData.userPercentageDiscount > 0">&nbsp;</template>
          <template v-else-if="code && code.code">
            <div class="flex items-center space-x-2 mt-3">
              <div class="flex-1 min-w-0">
                <p class="text-sm font-medium text-gray-900 truncate">
                  {{ $t('selectedSlots.discountCodeText') }}
                </p>
              </div>
              <div>
                <p class="text-sm font-bold">
                  {{ code.code }}
                </p>
              </div>
              <div class="flex-shrink-0" v-if="step === 0 || step === 1 || step === 2">
                <button @click="removeCode"
                        class="inline-flex items-center shadow-sm px-0.5 py-0.5 border border-gray-300 text-xs font-medium rounded-full text-gray-700 bg-white hover:bg-red-50 hover:border-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                  <XMarkIcon class="h-3 w-3 inline-block" aria-hidden="true"/>
                </button>
              </div>
            </div>
          </template>
          <template v-else-if="voucherCode && voucherCode.code">
            <div class="flex items-center space-x-2 mt-3">
              <div class="flex-1 min-w-0">
                <p class="text-sm font-medium text-gray-900 truncate">
                  {{ $t('selectedSlots.voucherCode') }}
                </p>
              </div>
              <div>
                <p class="text-sm font-bold">
                  {{ voucherCode.code }}
                </p>
              </div>
              <div class="flex-shrink-0" v-if="step === 0 || step === 1 || step === 2">
                <button @click="removeCode"
                        class="inline-flex items-center shadow-sm px-0.5 py-0.5 border border-gray-300 text-xs font-medium rounded-full text-gray-700 bg-white hover:bg-red-50 hover:border-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                  <XMarkIcon class="h-3 w-3 inline-block" aria-hidden="true"/>
                </button>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="flex items-center space-x-2 mt-3" v-if="discountCodeOpened === false">
              <div class="flex-1 min-w-0">
                  <a class="border border-gray-500 cursor-pointer rounded-md py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-500 hover:text-gray-700 focus:outline-none"
                     @click="discountCodeOpened = true">
                    {{ $t('selectedSlots.iHaveDiscountCode') }}
                  </a>
              </div>
            </div>
            <div v-else>
              <div class="flex items-center space-x-2 mt-3">
                <div class="flex-1 min-w-0">
                  <input v-model="discountCode"
                         type="text"
                         name="code"
                         id="code"
                         :placeholder="$t('selectedSlots.discountCodeInputPlaceholder')"
                         class="focus:ring-pg-yellow focus:border-pg-yellow block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                         :class="{'border-red-300': codeError !== null}"/>
                </div>
                <div>
                  <button :key="'useCodeButton'"
                          @click="useCode"
                          class="border border-green-500 rounded-md py-2 px-4 inline-flex justify-center text-sm font-medium text-green-500 hover:text-green-700 focus:outline-none">
                    {{ $t('selectedSlots.apply') }}
                  </button>
                </div>
              </div>
              <p class="mt-1 text-sm text-red-300" v-if="codeError">{{ codeError }}</p>
            </div>
          </template>
          <template v-if="step === 1 && selectedSlots && selectedSlots.length > 0 && selectedSlots.length < 10 && !(userData && userData.userPercentageDiscount && userData.userPercentageDiscount > 0)">
            <div class="bg-ps-yellow-3 px-3 py-2 mt-4 text-sm text-gray-900">
              <template v-if="selectedSlots.length < 5">
                <span class="font-bold">{{ $t('selectedSlots.tip') }}</span> <span v-html="$t('selectedSlots.discountTipText.' + (5 - selectedSlots.length === 1 ? '1' : '234'), { 'numberSlotsToSelect': 5 - selectedSlots.length, 'percentageDiscount': 4 })"/>
              </template>
              <template v-else-if="selectedSlots.length < 10">
                <span class="font-bold">{{ $t('selectedSlots.tip') }}</span> <span v-html="$t('selectedSlots.discountTipText.' + (10 - selectedSlots.length === 1 ? '1' : (10 - selectedSlots.length === 5 ? '5+' : '234')), { 'numberSlotsToSelect': 10 - selectedSlots.length, 'percentageDiscount': 10 })"/>
              </template>
            </div>
          </template>
          <template v-if="isThereNightSilentSlot() === true">
            <div class="bg-red-700 px-3 py-2 mt-4 text-sm text-white">
              <span class="font-bold">{{ $t('selectedSlots.nightSilenceLabel') }}</span><br>
              <span>{{ $t('selectedSlots.nightSilenceText') }}</span>
            </div>
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { GiftIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import {ChevronLeftIcon} from '@heroicons/vue/24/solid';
</script>

<script>

import { useStore } from '../js/store'
import { mapActions, mapState } from 'pinia'

export default {
  name: 'SelectedSlots',
  props: [
    'code',
    'codeError',
    'voucherCode',
    'showBackButton',
  ],
  emits: ['useCode', 'removeCode'],
  setup() {
    const store = useStore()

    return { store }
  },
  data: function() {
    return {
      discountCodeOpened: false,
      discountCode: ''
    }
  },
  watch: {
    returnGrandTotalPrice: function (val) {
      this.setTotalOrderPrice(val)
    }
  },
  computed: {
    ...mapState(useStore, {
      step: 'reservationStep'
    }),
    ...mapState(useStore, {
      selectedSlots: 'selectedSlots'
    }),
    ...mapState(useStore, {
      slotsToRebook: 'rebookSlots'
    }),
    ...mapState(useStore, {
      selectedAutomaticReservations: 'selectedAutomaticReservations'
    }),
    ...mapState(useStore, {
      selectedGiftCards: 'selectedGiftCards'
    }),

    ...mapState(useStore, {
      userData: 'user'
    }),

    getTotalPriceWithDiscount() {
      return this.getTotalPrice() - this.getDiscount()
    },

    getPercentageAmountDiscount() {
      if (this.userData && this.userData.userPercentageDiscount && this.userData.userPercentageDiscount > 0) {
        return this.userData.userPercentageDiscount
      } else if (this.code && this.code.type === 'percentage' && this.code.allow_quantity_discount === false) {
        return 0
      } else if (this.selectedSlots.length > 9) {
        return 10
      } else if (this.selectedSlots.length > 4) {
        return 4
      } else {
        return 0
      }
    },

    getTotalGiftCarsPrice() {
      let total = 0
      for (let i = 0; i < this.selectedGiftCards.length; i++) {
        total = total + this.selectedGiftCards[i].price
      }

      return total
    },

    returnDiscount() {
      return this.getDiscount()
    },

    returnTotalPrice() {
      return this.getTotalPrice()
    },

    returnTotalCodeDiscount() {
      if (this.userData && this.userData.userPercentageDiscount && this.userData.userPercentageDiscount > 0) {
        return 0
      }

      if (this.code && this.code.type === 'percentage') {
        return Math.ceil(parseFloat((this.getTotalPriceWithDiscount / 100 * this.code.percentage).toFixed(2)))
      } else if (this.code && this.code.type === 'value') {
        return Math.min(this.code.value, this.getTotalPriceWithDiscount)
      }
      return 0
    },

    returnTotalVoucherCodeDiscount() {
      if (this.voucherCode && this.voucherCode.rest_value) {
        return Math.min(this.voucherCode.rest_value, this.getTotalPriceWithDiscount)
      }
      return 0
    },

    returnGrandTotalPrice() {
      return Math.max((this.getTotalPriceWithDiscount - this.returnTotalCodeDiscount - this.returnTotalVoucherCodeDiscount), 0) + this.getTotalGiftCarsPrice
    }
  },
  methods: {
    ...mapActions(useStore, {removeSlot: 'removeSlot'}),

    ...mapActions(useStore, {removeGiftCard: 'removeGiftCard'}),

    ...mapActions(useStore, {setTotalOrderPrice: 'setTotalOrderPrice'}),

    ...mapActions(useStore, {removeAutomaticReservationFromList: 'removeAutomaticReservationFromList'}),

    ...mapActions(useStore, {hideReservationMobileInterStep: 'hideReservationMobileInterStep'}),

    useCode() {
      this.$emit('useCode', this.discountCode)
    },

    resorted(slotsArray) {
      let returnArray = []
      slotsArray.forEach((slot) => {
        let date = returnArray.find(s => s['date'] === slot.date)
        if (date) {
          date.slots.push(slot)
        } else {
          returnArray.push({
            date: slot.date,
            slots: [slot]
          })
        }
      })

      returnArray.forEach((day) => {
        day.slots.sort((a, b) => parseInt(a.from.replace(':', ''), 10) - parseInt(b.from.replace(':', ''), 10))
      })

      returnArray.sort((a, b) => this.$date(a.date, 'D.M.YYYY').format('YYYYMMDD') - this.$date(b.date, 'D.M.YYYY').format('YYYYMMDD'))

      return returnArray
    },
    removeCode() {
      this.$emit('removeCode')
    },
    getTotalPrice() {
      let total = 0

      for (let i = 0; i < this.slotsToRebook.length; i++) {
        if (this.slotsToRebook[i].isAllowedToRebook === true) {
          total = total - this.slotsToRebook[i].price
        }
      }

      for (let i = 0; i < this.selectedSlots.length; i++) {
        total = total + this.selectedSlots[i].price
      }

      return total < 0 ? 0 : total
    },

    getDiscount() {
      return Math.ceil(parseFloat(((this.getTotalPrice() / 100) * this.getPercentageAmountDiscount).toFixed(2)))
    },

    isThereNightSilentSlot() {
      for (let i = 0; i < this.selectedSlots.length; i++) {
        // n means if the slot is during night silent
        if (this.selectedSlots[i].n === true) {
          return true
        }
      }

      return false
    }
  },
  mounted() {
    this.setTotalOrderPrice(this.returnGrandTotalPrice)
  }
}
</script>
