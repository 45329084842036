<template>
  <nav class="sticky lg:hidden z-50 bottom-0 bg-white border-t border-ps-gray-border shadow-sm">
    <div class="max-w-3xl lg:max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8 py-3">
        <div class="relative flex min-w-0 flex-1 items-center" v-if="reservationMobileInterStep === false">
          <span class="relative inline-block flex-shrink-0" @click="toggleCartPanel">
            <button class="-mx-1 p-1 inline-flex items-center justify-center text-black hover:bg-white hover:text-black focus:outline-none focus:ring-0">
              <span class="sr-only">Open menu</span>
              <ShoppingCartIcon class="block h-5 w-5" aria-hidden="true" />
            </button>
          </span>
          <div class="inline-block ml-2 mr-1 text-xs" @click="toggleCartPanel">{{ totalOrderPrice }} Kč</div>
          <div class="ml-2" @click="toggleCartPanel">
            <p class="truncate text-xs font-medium text-gray-900">
              <template v-if="selectedSlots.length > 4">
                <span class="underline">{{ selectedSlots.length }} {{ $t('navBottomBar.selectedSlot5+') }}</span>
              </template>
              <template v-else-if="selectedSlots.length > 1">
                <span class="underline">{{ selectedSlots.length }} {{ $t('navBottomBar.selectedSlot2+') }}</span>
              </template>
              <template v-else-if="selectedSlots.length === 1">
                <span class="underline">{{ selectedSlots.length }} {{ $t('navBottomBar.selectedSlot1') }}</span>
              </template>
              <template v-else>
                <span class="text-gray-500">{{ $t('navBottomBar.noSelectedSlots') }}</span>
              </template>
              <template v-if="slotsToRebook.length > 0">
                <span class="text-gray-500">/</span> <span class="text-red-500"> {{ slotsToRebook.length }} {{ $t('navBottomBar.slotsToRebook') }}</span>
              </template>
            </p>
            <p class="truncate text-xs font-medium text-gray-900" v-if="selectedGiftCards.length > 0">
              <template v-if="selectedGiftCards.length > 4">
                <span class="underline">{{ selectedGiftCards.length }} {{ $t('navBottomBar.selectedGiftCards5+') }}</span>
              </template>
              <template v-else-if="selectedGiftCards.length > 1">
                <span class="underline">{{ selectedGiftCards.length }} {{ $t('navBottomBar.selectedGiftCards2+') }}</span>
              </template>
              <template v-else-if="selectedGiftCards.length === 1">
                <span class="underline">{{ selectedGiftCards.length }} {{ $t('navBottomBar.selectedGiftCards1') }}</span>
              </template>
            </p>
          </div>
        </div>
        <div class="relative flex min-w-0 flex-1 items-center" v-else>
          <button @click="hideReservationMobileInterStep()"
                  class="bg-gray-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
            {{ $t('navBottomBar.chooseMoreSlots') }}
          </button>
        </div>

        <div v-if="step === 0 || (step === 1 && reservationMobileInterStep === false)">
          <button v-if="selectedSlots.length > 0 || selectedGiftCards.length > 0"
                  @click="setStep(1); showReservationMobileInterStep()"
                  :key="'nextButton'"
                  class="bg-ps-yellow-3 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium hover:bg-ps-yellow-3-inactive focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ps-yellow-3-inactive">
            {{ $t('reservationComponent.continue') }}
          </button>
          <button v-else
                  disabled="disabled"
                  :key="'nextButtonDisabled'"
                  class="bg-gray-200 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-400 cursor-not-allowed">
            {{ $t('reservationComponent.continue') }}
          </button>
        </div>
        <div v-if="step === 1 && reservationMobileInterStep === true">
          <button v-if="selectedSlots.length > 0 || selectedGiftCards.length > 0"
                  @click="setStep(2)"
                  :key="'nextButton'"
                  class="bg-ps-yellow-3 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium hover:bg-ps-yellow-3-inactive focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ps-yellow-3-inactive">
            {{ $t('reservationComponent.continue') }}
          </button>
          <button v-else
                  disabled="disabled"
                  :key="'nextButtonDisabled'"
                  class="bg-gray-200 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-400 cursor-not-allowed">
            {{ $t('reservationComponent.continue') }}
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ShoppingCartIcon } from '@heroicons/vue/24/outline'

</script>

<script>

import {mapActions, mapState} from 'pinia'
import { useStore } from '../js/store'
import gtmTrackMixin from '../mixins/gtmTrackMixin'

export default {
  name: 'NavBottomBar',
  mixins: [gtmTrackMixin],

  methods: {
    ...mapActions(useStore, {setStep: 'setReservationStep'}),
    ...mapActions(useStore, {showReservationMobileInterStep: 'showReservationMobileInterStep'}),
    ...mapActions(useStore, {hideReservationMobileInterStep: 'hideReservationMobileInterStep'}),

    toggleCartPanel() {
      this.$emit('toggleCartPanel')
    }
  },
  mounted() {
  },
  computed: {
    ...mapState(useStore, {
      selectedSlots: 'selectedSlots'
    }),
    ...mapState(useStore, {
      reservationMobileInterStep: 'reservationMobileInterStep'
    }),
    ...mapState(useStore, {
      slotsToRebook: 'rebookSlots'
    }),
    ...mapState(useStore, {
      selectedGiftCards: 'selectedGiftCards'
    }),
    ...mapState(useStore, {
      userData: 'user'
    }),
    ...mapState(useStore, {
      step: 'reservationStep'
    }),
    ...mapState(useStore, {
      totalOrderPrice: 'totalOrderPrice'
    }),
    currentRouteName() {
      return this.$route.name;
    }
  }
}
</script>

<style scoped>

</style>
