<template>
  <footer class="bg-gray-50">
    <div class="mx-auto max-w-md py-8 px-4 overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8 text-xs md:text-sm">
      <nav class="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
        <div class="px-5 py-1 lg:py-2">
          <a href="mailto:podpora@privategym.cz" class="no-underline hover:underline text-gray-400">
            podpora@privategym.cz
          </a>
        </div>
        <div class="px-5 py-1 lg:py-2">
          <a href="https://www.privategym.cz/ps-vseobecne-obchodni-podminky" target="_blank" class="no-underline hover:underline text-gray-400">
            {{ $t('footer.termAndConditions') }}
          </a>
        </div>
        <div class="px-5 py-1 lg:py-2">
          <a href="https://www.privategym.cz/ps-zasady-zpracovani-osobnich-udaju" target="_blank" class="no-underline hover:underline text-gray-400">
            {{ $t('footer.personalData') }}
          </a>
        </div>
        <div class="px-5 py-1 lg:py-2">
          <a href="" data-cc="c-settings" class="no-underline hover:underline text-gray-400">
            {{ $t('footer.cookiesSetting') }}
          </a>
        </div>
      </nav>
      <div class="mt-4 mb-4 lg:mt-6 lg:mb-6 flex flex-wrap flex-row justify-center space-x-4 items-center">
        <img class="max-h-6 md:max-h-9" alt="Mastercard" src="../assets/img/pay/mc_1.png"/>
        <img class="max-h-6 md:max-h-9" alt="Maestro" src="../assets/img/pay/mc_2.png"/>
        <img class="max-h-5 md:max-h-8" alt="Visa" src="../assets/img/pay/visa_1.png"/>
        <img class="max-h-5 md:max-h-8" alt="Visa Electron" src="../assets/img/pay/visa_2.png"/>
      </div>
      <p class="mt-4 text-center text-gray-400">&copy; {{ year }} Private Space s.r.o.</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  data: function () {
    return {
      year: new Date().getFullYear(),
    }
  },
}
</script>

<style scoped>

</style>
