<template>
<!--  <div class="flex items-center gap-x-2 bg-black px-1.5 py-1.5 sm:px-3.5" v-if="step < 2 && openGiftPanel === true">-->
<!--    <p class="text-sm flex-1 text-center leading-6 text-pink-400 animate-pulse">-->
<!--      <button @click="$router.push({ name: 'GiftPage' })">-->
<!--        <span class="text-xl">🩷</span>&nbsp;&nbsp;<strong class="font-semibold">Dárek k Valentýnu na poslední chvíli?</strong><span class="inline md:hidden"><br></span><svg viewBox="0 0 2 2" class="mx-2 hidden md:inline h-0.5 w-0.5 fill-current" aria-hidden="true"><circle cx="1" cy="1" r="1" /></svg>Daruj poukaz do PrivateGym&nbsp;&nbsp;<ArrowRightIcon class="h-3 w-3 text-white inline" aria-hidden="true" />-->
<!--      </button>-->
<!--    </p>-->
<!--    <div class="flex justify-end">-->
<!--      <button type="button" class="-m-2 p-2 sm:-m-3 sm:p-3 focus-visible:outline-offset-[-4px]" @click="openGiftPanel = false">-->
<!--        <span class="sr-only">Dismiss</span>-->
<!--        <XMarkIcon class="h-5 w-5 text-white" aria-hidden="true" />-->
<!--      </button>-->
<!--    </div>-->
<!--  </div>-->
  <nav class="lg:sticky z-40 top-0 bg-ps-yellow-3 shadow-sm">
<!--  <nav class="lg:sticky z-40 top-0 bg-ps-yellow-3 shadow-sm" :class="{'hidden' : step > 0}">-->
    <div class="max-w-3xl lg:max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8">
        <div class="flex lg:static xl:col-span-6 py-3">
          <div class="flex-shrink-0 flex items-center">
            <router-link :to="{ name: 'NewReservation', params: { locale: $route.params.locale } }">
              <img src="../assets/img/logo/pg-logo.svg" alt="PrivateGym.cz" class="h-8 w-auto inline"/>
              <span class="hidden md:inline">&nbsp;&nbsp;&nbsp;<span>|</span>&nbsp;&nbsp;&nbsp;{{ $t('navBar.header') }}</span>
            </router-link>
          </div>
        </div>

        <div class="flex items-center absolute right-0 inset-y-0">
          <template v-if="userData.hasOwnProperty('userEmail')">
            <user-menu-component :user-data="userData"/>
          </template>

          <template v-else>
            <button @click="openLoginModal" class="text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium focus:outline-none" aria-current="page">{{ $t('navBar.signInButton') }}</button>
            <button @click="openRegistrationModal" class="shadow-sm text-gray-600 hover:text-gray-900 border border-gray-500 hover:border-gray-900 px-3 py-2 rounded-md text-sm font-medium">{{ $t('navBar.signUpButton') }}</button>
          </template>

          <language-menu-component/>

        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import UserMenuComponent from './UserMenuComponent'
import LanguageMenuComponent from './LanguageMenuComponent'
// import { XMarkIcon } from '@heroicons/vue/24/solid'
// import { ArrowRightIcon } from '@heroicons/vue/24/outline'

</script>

<script>

import { mapState } from 'pinia'
import { useStore } from '../js/store'

export default {
  name: 'NavBar',
  data: function () {
    return {
      openGiftPanel: true
    }
  },
  methods: {
    toggleCartPanel() {
      this.$emit('toggleCartPanel')
    },
    openLoginModal() {
      this.$emit('openLoginModal')
    },
    openRegistrationModal() {
      this.$emit('openRegistrationModal')
    }
  },
  mounted() {
  },
  computed: {
    ...mapState(useStore, {
      step: 'reservationStep'
    }),
    ...mapState(useStore, {
      selectedSlots: 'selectedSlots'
    }),
    ...mapState(useStore, {
      userData: 'user'
    }),
    currentRouteName() {
      return this.$route.name;
    }
  }
}
</script>

<style scoped>

</style>
